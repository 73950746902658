import { ThemeProvider, createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#1f3564", //blue remax
    },
  },
  
});


export default theme;


