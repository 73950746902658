import Styled from "@emotion/styled";
import MenuIcon from "@mui/icons-material/Menu";
import Link from "next/link";
import React, { useState } from "react";
import {
  useCollectionIntlNavLinks,
  useRemaxIntlNavLinks,
} from "shared/models/const/navsLink.const";
import Logo from "./Logo";
import { Box, Drawer, IconButton, List } from "@mui/material";
import { Spacing } from "..";
import { useRouter } from "next/router";
import { CPagesPath } from "shared/models/const/pages-path";
import ContactDialog from "shared/components/contact-dialog";
import { useTranslations } from "next-intl";
import { CIdioms } from "shared/models/const/idiomsconst";
import MobileMenuItem from "shared/components/mobile-menu-item";
import AuthButtons from "shared/components/auth-buttons";
import FlatsSelector from "./flats";

const MobileMenu = React.forwardRef(function Menu(
  props: any,
  ref: React.Ref<unknown>
) {
  const [open, isOpen] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const handleToggleMenu = () => {
    isOpen(!open);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <>
      <Logo></Logo>
      <Spacing />
      <FlatsSelector />
      <ToggleIconMenu onClick={handleToggleMenu}>
        <MenuIcon />
      </ToggleIconMenu>
      <ContentSliderMenu
        open={open}
        handleToggleMenu={handleToggleMenu}
        handleOpenModal={handleOpenModal}
      />
      <ContactDialog open={openModal} onClose={handleCloseModal} />
    </>
  );
});

export default MobileMenu;

interface IContentSliderMenuProps {
  open: boolean;
  handleToggleMenu: () => void;
  handleOpenModal: () => void;
}

const ContentSliderMenu = (props: IContentSliderMenuProps) => {
  return (
    <Box component="nav">
      <SliderBox
        anchor="right"
        open={props.open}
        onClose={props.handleToggleMenu}
      >
        <DrawerItems {...props} />
      </SliderBox>
    </Box>
  );
};

const DrawerItems = (props: any) => {
  const location = useRouter();
  const remaxLinks = useRemaxIntlNavLinks();
  const collectionLinks = useCollectionIntlNavLinks();
  const translation = useTranslations("header");
  let paths = remaxLinks;

  if (location.pathname.includes(CPagesPath.es.collection)) {
    paths = collectionLinks;
  }

  return (
    <>
      <Box sx={{ textAlign: "center" }}>
        {/* <Logo /> */}
        <List sx={{}} onClick={props.handleToggleMenu}>
          {paths.map((item, i) => {
            if (item.slug === translation("contact")) {
              return (
                <MobileMenuItem
                  item={item}
                  key={i}
                  handleOpen={props.handleOpenModal}
                />
              );
            }

            return <MobileMenuItem item={item} key={i} />;
          })}
          <AuthButtons mobile={true} />
        </List>
        <Language>
          {CIdioms.map((idioms, i) => (
            <Link
              href={`${idioms.link + location.asPath}`}
              key={i}
              locale={false}
            >
              <a onClick={props.handleToggleMenu}>
                <Image src={idioms.icon} alt={idioms.alt} />
              </a>
            </Link>
          ))}
        </Language>
      </Box>
    </>
  );
};

const ToggleIconMenu = Styled(IconButton)`
    flex-self: flex-end;
`;

const SliderBox = Styled(Drawer)`
  width: 475px;
  & .MuiDrawer-paper {
    box-sizing: 'border-box';
    width: 60vw;
    border-radius:10px 0px 10px 0px;
   }
`;

const Image = Styled.img`
  width: 30px;
  height: 20px;
`;

const Language = Styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 30px;
  max-width: 80px;
  margin-left: auto;
  margin-right: auto;
`;
