import React from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import styled from "@emotion/styled";
import { IconButton, InputAdornment } from "@mui/material";

export const TogglePasswordIcon = (props: ITogglePasswordIcon) => {
	const { handleToggle, showPassword } = props;
	return (
		<InputAdornmentCustom position="end">
			<IconButton
				className="button"
				aria-label="toggle password visibility"
				onClick={handleToggle}
				tabIndex={-1}
			>
				{showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
			</IconButton>
		</InputAdornmentCustom>
	);
};

interface ITogglePasswordIcon {
	handleToggle: () => void;
	showPassword: boolean;
}

const InputAdornmentCustom = styled(InputAdornment)`
  position: absolute;
  right: 10px;
`;