import React from "react";
import { useAppDispatch, useAppSelector } from "custom-hooks/redux.hooks";
import { useTranslations } from "next-intl";
import { CPagesPath } from "shared/models/const/pages-path";
import { openAuthDialog } from "store/feature/auth/auth-dialog.slice";
import { logout } from "store/feature/auth/auth.slice";
import MobileMenuItem from "../mobile-menu-item";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LogoutIcon from "@mui/icons-material/Logout";
import ProfilePicture from "../profile-picture";
import useUserSettingsMenuItems from "custom-hooks/use-user-settings-menu-items.hook";

export default function MobileSignInButtons() {
  const t = useTranslations("header");
  const user = useAppSelector((state) => state.Auth.user);
  const dispatch = useAppDispatch();
  const menu = useUserSettingsMenuItems();

  return (
    <>
      {(user
        ? [
            {
              slug: user.full_name,
              link: CPagesPath.es.profile,
              iconComponent: (
                <ProfilePicture src={user.profile_picture} size={30} />
              ),
            },
            ...menu,
            {
              slug: t("user-logout"),
              icon: LogoutIcon,
              handleOpen: () => dispatch(logout()),
            },
          ]
        : [
            {
              slug: t("user-signup"),
              link: CPagesPath.es.signin,
              icon: AccountCircleIcon,
              handleOpen: () => dispatch(openAuthDialog("signup")),
            },
          ]
      ).map((item, i) => (
        <MobileMenuItem item={item} key={i} handleOpen={(item as any).handleOpen} />
      ))}
    </>
  );
}
