import Styled from "@emotion/styled";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { useWidth } from "custom-hooks/use-width.hook";
import MobileMenu from "./components/MobileMenu";
import DesktopMenu from "./components/DesktopMenu";
import React from "react";
import AuthDialogs from "../auth-dialogs";
import News from "@components/news";
import { useShowNews } from "shared/context/news.context";

function NavBar() {
  const width = useWidth();
  const { showNews } = useShowNews();

  return (
    <>
      <ContentNav showNews={showNews}>
        <MXAppBar>
          <News />
          <MXToolbar>
            {["sm", "xs","md"].includes(width) ? <MobileMenu /> : <DesktopMenu />}
          </MXToolbar>
          <AuthDialogs />
        </MXAppBar>
      </ContentNav>
    </>
  );
}

export default NavBar;

export const Spacing = Styled.span`
  flex:1;
`;

const ContentNav = Styled.div<IContentNav>`
  margin-top: 74px;
  ${({ showNews }) => showNews && "margin-top: 125px;"}
`;

const MXAppBar = Styled(AppBar)`
    width: 100%;
    background-color: #fff;
    box-shadow: none;
`;

const MXToolbar = Styled(Toolbar)`
  display: flex;
  flex-direction: row;
  height: 73px;
  width: 100%;
  margin:0 auto;
  @media screen and (min-width:600px) {
      padding-left: 54px;
      padding-right: 0px;
  }
`;

interface IContentNav {
  showNews: boolean;
}
