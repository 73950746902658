import React from "react";
import DesktopAuth from "./DesktopAuth";
import MobileAuth from "./MobileAuth";

interface IAuthItemProps {
  mobile?: boolean;
}

export default function AuthButtons({ mobile }: IAuthItemProps) {
  // if (process.env.NEXT_PUBLIC_NODE_ENV !== "stage") return null;

  return mobile ? <MobileAuth /> : <DesktopAuth />;
}
