import { css } from "@emotion/react";
import { Input, InputProps } from "@mui/material";
import { useField } from "formik";
import React from "react";
import LabelField from "../label-field";
import DefaultTextField, { ITextFieldComponent } from "./default-text-field";

function FormikTextField(props: ITextFieldComponent) {
  const [field, meta, helpers] = useField(props as any);
  const { name,onChange, ...res } = props;

  const handleChange = (value: any) => {
    if (value.target) {
      field.onChange(value);
    } else {
      helpers.setValue(value);
    }
  };

  const config: ITextFieldComponent = {
    ...field,
    ...res,
    ...{ id: name },
    onChange: props.onChange || handleChange,
    errorsComponents: ErrorsComponents({ meta }),
  };

  return <DefaultTextField {...config}></DefaultTextField>;
}

export default FormikTextField;

const ErrorsComponents = ({ meta }: any) => (
  <>
    {meta.error && meta.touched && (
      <span css={styles.inputErrors}>{meta.error}</span>
    )}
  </>
);

export const styles = {
  inputField: css({
    width: "100%",
    outline: "none",
    border: "1px solid var(--color-border)",
    borderRadius: "50px",
    padding: "8px 23px",
    background: "none",
    color: "var(--color-blue-main)",
    fontFamily: "var(--font-family)",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "var( --fs-body-small)",
    lineHeight: "21px",
    "& input::placeholder": {
      color: "var(--color-blue-main)",
      opacity: "1",
    },
    "&:hover": {
      border: "1px solid var(--color-border-hover)",
    },
  }),

  contentInput: css({
    width: "100%",
    // minHeight: "80px",
  }),

  inputErrors: css({
    width: "100%",
    color: "var(--color-red-error)",
    fontWeight: 500,
    paddingLeft: "10px",
    fontSize: "0.8125rem",
    display: "flex",
    alignItems: "center",
    "& .iconError": {
      fontSize: "0.8125rem",
      marginRight: 3,
    },
  }),
};
