import styled from "@emotion/styled";
import { getLoginRedirectURL } from "api/auth.request";
import { useTranslations } from "next-intl";
import Image from "next/image";
import React from "react";

interface IOAuthEntity {
	name: string;
	link: string;
	icon: string;
	show: boolean;
}

const OAuthEntities: IOAuthEntity[] = [
  {
    name: "Facebook",
    link: getLoginRedirectURL("facebook"),
    icon: "/icons/icon_facebook_logo.png",
    show: process.env.NEXT_PUBLIC_NODE_ENV === "stage",
  },
  {
    name: "Google",
    link: getLoginRedirectURL("google"),
    icon: "/icons/icon-google-logo.png",
    show: true,
  },
];

export default function OAuthButtons() {
	const t = useTranslations("signUpForm");
	
	return (
		<>{
			OAuthEntities.filter(x=>x.show).map(({ name, link, icon }, i) => (
				<SignInWith key={i} href={link}>
					<Image src={icon} alt={name + ' icon'} width={26} height={26} />
					<span css={styles.oauth_btn_lbl}>
						{t("oAuthButton", { social: name })}
					</span>
				</SignInWith>
			))
		}</>
	)
}

const SignInWith = styled.a`
width: 100%;
border: 1px solid #D4D4D4;
display: flex;
justify-content: center;
max-width: 363px;
height: 44px;
align-items: center;
border-radius: 22px;
margin: auto;

&:hover {
	background-color: #f6f6f696;
}

& + & {
	margin-top: 14px;
}
`

const styles = {
	oauth_btn_lbl: {
		fontWeight: 'bold',
		marginLeft: '10px'
	}
}