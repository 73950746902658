import Link from "next/link";
import React from "react";
import Logo from "./Logo";
import Styled from "@emotion/styled";
import { Spacing } from "..";
import { useState } from "react";
import { CPagesPath } from "shared/models/const/pages-path";
import { useRouter } from "next/router";
import ContactDialog from "shared/components/contact-dialog";
import { useTranslations } from "next-intl";
import {
  useRemaxIntlNavLinks,
  useCollectionIntlNavLinks,
} from "shared/models/const/navsLink.const";
import AuthButtons from "shared/components/auth-buttons";
import FlatsSelector from "./flats";

const DesktopMenu = () => (
  <>
    <Logo />
    <Spacing />
    <NavLinksRender />
    <FlatsSelector />
    <AuthButtons />
  </>
);

export default DesktopMenu;

const NavLinksRender = () => {
  const [open, setOpen] = useState<boolean>(false);
  const location = useRouter();
  const remaxLinks = useRemaxIntlNavLinks();
  const collectionLinks = useCollectionIntlNavLinks();
  const translation = useTranslations("header");
  let paths = remaxLinks;

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (location.pathname.includes(CPagesPath.es.collection)) {
    paths = collectionLinks;
  }

  const basePath = getBasePath(location.pathname);

  return (
    <>
      <ContainerLinks className="navbar_desktop__list_items">
        {paths.map((item, i) => {
          if (!item.ignoredRoutes?.includes(basePath)) {
            if (item.slug === translation("contact")) {
              return (
                <li key={i}>
                  <ModalBtn onClick={handleOpen}>{item.slug}</ModalBtn>
                </li>
              );
            }

            return (
              <li key={i}>
                {!item?.target && (
                  <Link href={item.link ?? ""} passHref>
                    <a className={item?.bold ? "bold" : ""}>{item.slug}</a>
                  </Link>
                )}
                {item?.target && (
                  <a href={item.link} target="_blank" rel="noopener noreferrer">
                    {item.slug}
                  </a>
                )}
              </li>
            );
          }
        })}
      </ContainerLinks>
      <ContactDialog
        open={open}
        onClose={handleClose}
        classNameModalButton="enviar-formulario-contacto-menu"
      />
    </>
  );
};

const getBasePath = (pathName: string) => {
  const indexOfSecondSlash = pathName.substring(1).indexOf("/");

  if (indexOfSecondSlash > -1) {
    return pathName.substring(0, indexOfSecondSlash + 1);
  }

  return pathName;
};

const ContainerLinks = Styled.ul`
  list-style: none;
  display: flex;

  li{
   display: inline-block;
    margin-right: 18px;
    font-family: var(--font-family);
    font-size: 0.938rem;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0px;
    vertical-align: middle;
    a.bold{
      font-weight: bold;
    }
    
    @media screen and (max-width: 1000px) {
      font-size: 0.9rem;
      margin-right: 8px;
    }
  }
`;

const ModalBtn = Styled.a`
  cursor: pointer;
`;

const FlagsContainer = Styled.div`
    border-left: 1px solid #000;
    border-right: 1px solid #000;
    padding: 0 15px;
    margin-right: 5px;
    font-weight: 700;
    color: var(--color-blue-main);
    display:flex;
    align-items: center;

    a {
      display: inline-block;
      margin-left: 15px;
    }
    img{
      width: 30px;
      vertical-align: middle;
    }

    .select-idiom{
      border: none;

    }

    @media screen and (max-width: 1000px) {
      padding: 0 10px;
      font-size: 14px;
    }
`;
