import Image from "next/image";
import React from "react";
import packageJson from "../../../../package.json";
import styled from "@emotion/styled";
import { useTranslations } from "next-intl";

function PowerBy() {
  const projectV = packageJson?.version;
  const translation = useTranslations("footer.powerBy");

  return (
    <PowerByContainer>
      <LogoRemax
        src="/icons/remax-rd.png"
        alt="Logo Remax"
        width={200}
        height={60}
      />
      <p style={{ paddingTop: "10px" }}>{translation("title")}</p>
      <p>
        {translation.rich("subtitle", {
          link: (chunks) => (
            <a href="https://version.do/" target="_black">
              {chunks}-
            </a>
          ),
          version: projectV,
        })}
      </p>
    </PowerByContainer>
  );
}

export default PowerBy;

const PowerByContainer = styled.section`
  width: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 30px;
  align-items: center;
  text-align: center;
  p {
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 13px;
    display: block;
    color: var(--color-gray-01);
  }
  a {
    color: #5db748;
  }
`;

const LogoRemax = styled(Image)`
  padding-bottom: 10px;
`;
