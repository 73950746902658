import React, { useState } from "react";
import styled from "@emotion/styled";
import { Dialog } from "@mui/material";
import { useMutation } from "@tanstack/react-query";

import useSnackBar from "custom-hooks/useSnackBar.hook";
import ContactForm from "../contact-agent-dialog/ContactForm";
import { postMessageContact } from "api/messages.request";
import { IFormValues } from "../contact-agent-dialog";
import { ModalMessage } from "shared/components/message-modal";
import { useTranslations } from "next-intl";

const postData = async (data: IFormValues) => {
  const response = await postMessageContact(data);
  return response;
};

function ContactDialog(props: IContactModal) {
  const { open, onClose } = props;
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [isSubscribed, setIsSubscribed] = useState<boolean>(false);
  const translation = useTranslations("alerts.contact");
  const translationTitle = useTranslations("contactForm");

  const { showMessage } = useSnackBar();

  const { mutateAsync, isLoading } = useMutation(postData, {
    onSuccess: () => {
      handleOpenModal();
      onClose();
    },
    onError: () =>
      showMessage(translation("error"), {
        variant: "error",
      }),
  });

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const onSubmit = (values: IFormValues) => {
    const { captcha, ...rest } = values;
    const data: any = {
      ...rest,
    };

    data["g-recaptcha-response"] = captcha;

    setIsSubscribed(data["subscribe"])
    mutateAsync(data);
  };

  return (
    <>
      <StyledDialog open={open}>
        <ContactForm
          onSubmit={onSubmit}
          onClose={onClose}
          title={translationTitle("title")}
          classNameModalButton={props.classNameModalButton}
          isLoading={isLoading}
          className="contact"
        />
      </StyledDialog>
      <StyledModalMessage
        title={translation("titleSuccess")}
        body={isSubscribed ? translation("bodySuccessSubscribe") : translation("bodySuccess")}
        open={openModal}
        handleClose={handleCloseModal}
      />
    </>
  );
}

export default ContactDialog;

const StyledDialog = styled(Dialog)`
  & .MuiDialog-paper {
    background-color: var(#efeff1);
    border-radius: 20px;
  }
`;

const StyledModalMessage = styled(ModalMessage)`
  & .MuiBox-root {
    width: 90%;
    border-radius: 20px;
    max-width: 400px;
  }
`;

interface IContactModal {
  onClose: any;
  open: boolean;
  classNameModalButton?: string;
}
