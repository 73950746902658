import * as yup from "yup";
import PasswordField from "@components/text-field/password-field";
import styled from "@emotion/styled";
import Link from "next/link";
import { Divider, Grid } from "@mui/material";
import { Form, Formik } from "formik";
import { useTranslations } from "next-intl";
import { useState } from "react";
import { ISignInForm } from "shared/models/interfaces/clients.interfaces";
import { useMutation } from "@tanstack/react-query";
import {
  userResendConfirmEmail,
  userSignIn,
} from "api/clients/clients.request";
import { CPagesPath } from "shared/models/const/pages-path";
import { setToken, setUser } from "store/feature/auth/auth.slice";
import { useAppDispatch } from "custom-hooks/redux.hooks";
import { IModalMessage } from "@interfaces/components/message-modal.interface";

import Button from "../Button";
import OAuthButtons from "../oAuthButtons";
import TextField from "../text-field";
import { ModalMessage } from "../message-modal";

interface ISignInFormProps {
  onLoginSuccess: (data?: any) => void;
  openSignUp?: () => void;
  openRestorePassword?: () => void;
}

const initialValues: ISignInForm = {
  email: "",
  password: "",
};

export default function SignInForm(props: ISignInFormProps) {
  const { openSignUp, onLoginSuccess, openRestorePassword } = props;
  const [Modal, SetModal] = useState<IModalMessage | any>();
  const [ToggleModal, SetToggleModal] = useState<boolean>(false);

  const t = useTranslations("signinForm");
  const alertT = useTranslations("alerts");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const dispatch = useAppDispatch();
  let goToSignUp: any;
  let gotToRestorePassword: any;

  const validationSchema = yup.object({
    email: yup.string().required(""),
    password: yup.string().required(""),
  });

  const resendConfirmEmailM = useMutation(userResendConfirmEmail, {
    onSuccess: (res) => {
      SetModal({
        open: true,
        title: alertT("resendEmailSuccess.title"),
        body: alertT("resendEmailSuccess.body"),
        buttonText: alertT("resendEmailSuccess.button"),
        handleClose: () => SetModal({ ...Modal, open: false }),
      });
    },
    onError: ({ response }) => {},
  });

  const { isLoading, isSuccess, mutateAsync } = useMutation(userSignIn);

  function onSubmit(values: ISignInForm) {
    mutateAsync(values)
      .then((res) => {
        const { user, token } = res;
        dispatch(setUser(user));
        dispatch(setToken(token));
        onLoginSuccess();
      })
      .catch((err) => {
        const { response } = err;
        if (response?.status === 422) {
          if (response.data?.errors?.email) {
            SetModal({
              variant: "error",
              open: true,
              title: alertT("resendEmail.title"),
              body: alertT("resendEmail.body"),
              buttonText: alertT("resendEmail.button"),
              handleClose: (e: any) => {
                SetModal({ ...Modal, open: false });
                if (e == "forceClose") {
                  return;
                }
                resendConfirmEmailM.mutate({ email: values.email });
              },
            });
            return;
          }
          setErrorMessage(t("error.message"));
        } else {
          setErrorMessage(t("error.genericMessage"));
        }
      });
  }

  if (openSignUp) {
    goToSignUp = (
      <SignUpButton onClick={openSignUp} className="redirect">
        {t("user-signup")}
      </SignUpButton>
    );
  } else {
    goToSignUp = (
      <Link href={CPagesPath.es.signup}>
        <SignUpButton className="redirect">{t("user-signup")}</SignUpButton>
      </Link>
    );
  }

  if (openRestorePassword) {
    gotToRestorePassword = (
      <RestorePasswordButton onClick={openRestorePassword}>
        {t("restore-password")}
      </RestorePasswordButton>
    );
  } else {
    gotToRestorePassword = (
      <Link href={CPagesPath.es.forgotPassword}>
        <RestorePasswordButton>{t("restore-password")}</RestorePasswordButton>
      </Link>
    );
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {(formik) => (
        <Form className="signin-form-hubspot-remaxrd" >
          <Grid container mb={3}>
            <Grid item xs={12} sm={9} textAlign="center" margin="auto">
              <h3>
                <BoldTitle>{t("header.title")}</BoldTitle>
                &nbsp;
                {/* <ThinTitle>RE/MAX RD</ThinTitle> */}
              </h3>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={9} mx="auto">
              <OAuthButtons></OAuthButtons>
            </Grid>

            <Grid
              container
              sx={{ mt: 4, mb: 1 }}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Grid item xs={5}>
                <Divider variant="middle" />
              </Grid>
              <Grid item xs={2} sx={{ textAlign: "center" }}>
                <OpcionalMsg>o bien</OpcionalMsg>
              </Grid>
              <Grid item xs={5}>
                <Divider variant="middle" />
              </Grid>
            </Grid>
            <Grid item xs={12} sm={8} margin="auto">
              <TextField name="email" label={t("field.email")} />
            </Grid>
            <Grid item xs={12} sm={8} margin="auto">
              <PasswordField
                name="password"
                label={t("field.password") + "*"}
              />
            </Grid>
            <Grid item xs={12} textAlign="center" my={1}>
              <Button
                type="submit"
                fs="12px"
                bg="--color-secondary"
                thin_button
                disabled={!formik.isValid || isLoading || isSuccess}
                loadingMessage={isLoading ? t("sending") : null}
              >
                {t("submitButton")}
              </Button>
            </Grid>
            <Grid item xs={12} textAlign="center">
              {goToSignUp}
              &nbsp;|&nbsp;
              {gotToRestorePassword}
            </Grid>
          </Grid>
          <ModalMessage
            title="Aviso"
            body={errorMessage}
            variant="error"
            open={!!errorMessage}
            handleClose={() => setErrorMessage("")}
          />
          <ModalMessage {...Modal} />
        </Form>
      )}
    </Formik>
  );
}

const BoldTitle = styled.span`
  font-weight: 700;
  text-transform: uppercase;
`;

const OpcionalMsg = styled.span`
  font-size: 12px;
  color: var(--color-dark-gray);
  text-transform: uppercase;
  font-weight: 500;
`;

const ThinTitle = styled.span`
  font-weight: 300;
  text-transform: uppercase;
`;

const SignUpButton = styled.a`
  font-size: 14px;
  font-weight: 500;
  line-height: 13px;
  color: var(--color-gray-02);
  cursor: pointer;
  &.redirect {
    color: var(--color-blue-main);
    font-weight: 700;
  }
`;

const RestorePasswordButton = styled.a`
  font-size: 14px;
  font-weight: 500;
  line-height: 13px;
  color: var(--color-gray-02);
  cursor: pointer;
`;
