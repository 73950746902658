import { CPagesPath } from "./pages-path";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import GroupIcon from "@mui/icons-material/Group";
import BookIcon from "@mui/icons-material/Book";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import CardMembershipIcon from "@mui/icons-material/CardMembership";
import { useTranslations } from "next-intl";
import { INavLink } from "../interfaces/components/navlinks.interface";

export function useRemaxIntlNavLinks() {
  const translation = useTranslations("header");

  const CNavLinksRemaxRd: INavLink[] = [
    {
      slug: translation("buy"),
      link: CPagesPath.es.shop,
      icon: ShoppingBagIcon,
      ignoredRoutes: ["/propiedades", "/proyectos"],
    },
    {
      slug: translation("rent"),
      link: CPagesPath.es.acquire,
      icon: RequestQuoteIcon,
      ignoredRoutes: ["/propiedades", "/proyectos"],
    },
    {
      slug: translation("collection"),
      link: CPagesPath.es.collection,
      icon: CardMembershipIcon,
    },
    {
      slug: translation("agentAgencyList"),
      link: CPagesPath.es.agencies_list,
      icon: GroupIcon,
    },
    {
      slug: translation("blog"),
      link: CPagesPath.blog,
      icon: BookIcon,
      target: "_blank",
      ignoredRoutes: ["/propiedades", "/proyectos"],
    },
    {
      slug: translation("contact"),
      link: "/contactanos",
      icon: AlternateEmailIcon,
    },
  ];

  return CNavLinksRemaxRd;
}

export function useCollectionIntlNavLinks() {
  const translation = useTranslations("collectionHeader");

  const CNavLinksCollection: INavLink[] = [
    {
      slug: translation("return"),
      link: "/",
      icon: RequestQuoteIcon,
      bold: true,
    },
    {
      slug: translation("properties"),
      link: CPagesPath.es.collection_properties,
      icon: ShoppingBagIcon,
      bold: true,
    },
  ];

  return CNavLinksCollection;
}

export const CNavLinksRemaxRd: INavLink[] = [
  {
    slug: "Comprar",
    link: CPagesPath.es.shop,
    icon: ShoppingBagIcon,
    ignoredRoutes: ["/propiedades", "/proyectos"],
  },
  {
    slug: "Alquilar",
    link: CPagesPath.es.acquire,
    icon: RequestQuoteIcon,
    ignoredRoutes: ["/propiedades", "/proyectos"],
  },
  {
    slug: "Collection",
    link: CPagesPath.es.collection,
    icon: CardMembershipIcon,
  },
  {
    slug: "Agencias / Agentes",
    link: CPagesPath.es.agencies_list,
    icon: GroupIcon,
  },
  {
    slug: "Blog",
    link: CPagesPath.blog,
    icon: BookIcon,
    target: "_blank",
  },
  {
    slug: "Contáctanos",
    link: "/contactanos",
    icon: AlternateEmailIcon,
  },
];

export const CNavLinksCollection: INavLink[] = [
  {
    slug: "Volver a RE/MAX RD",
    link: "/",
    icon: RequestQuoteIcon,
    bold: true,
  },
  {
    slug: "Propiedades",
    link: CPagesPath.es.collection_properties,
    icon: ShoppingBagIcon,
    bold: true,
  },
];
