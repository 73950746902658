enum MessagesStatic {
  /**
   * @member {string}
   * @method post
   * @version 2.0
   * @description post a message
   */
  Contact = "/v2/messages/conctact",
  Franchise = "v2/messages/franchise",
  Loan = "/v2/messages/loan",
}

const MessagesDynamic = {
  /**
   * @member {string}
   * @method post
   * @version 2.0
   * @description post a message
   */
  Agent: (id: number | string) => `/v2/messages/agent/${id}`,
};

export const MessagesEndpoint = {
  ...MessagesStatic,
  ...MessagesDynamic,
};

export type MessagesEndpoint = typeof MessagesEndpoint;
