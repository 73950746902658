import { css } from '@emotion/react';
import React from 'react';


export interface LabelFieldInterface {
  label?: string;
  positionLabel?: "center" | "right" | "left";
}

const LabelField = (props: LabelFieldInterface) => {
  return (
    <span css={styles.label} className={`label-customs ${props.positionLabel}`}>
      {props.label}
    </span>
  );
};

export default LabelField

const styles = {
  label: css({
    width: "100%",
    display: "block",
    paddingLeft: "10px",
    fontSize: "0.9375rem",
    fontWeight: 600,
    lineHeight: "21px",
    color: "var(--color-blue-main)",
    verticalAlign: "bottom !important",
    marginBottom: '10px',

    "&.center": {
      textAlign: "center",
    },
    "&.left:": {
      textAlign: "left",
    },
    "&.right:": {
      textAlign: "right",
    },
  }),
};
  