import React, { useEffect, useRef } from "react";
import styled from "@emotion/styled";
import { Form, Formik } from "formik";
import * as yup from "yup";
import Reaptcha from "reaptcha";
import { DialogContent, DialogActions, DialogTitle, Grid } from "@mui/material";
import RecaptchaField from "../recaptcha-field";
import Button from "../Button";
import TextField from "../text-field";
import { PhoneMask, TextMaskInput } from "../mask-components";
import { useTranslations } from "next-intl";
import CheckboxCustom from "shared/components/checkBox";
import LabelField from "shared/components/label-field";
import useGetPersistParams from "custom-hooks/useGetPersintParamas.hook";

interface IContactForm {
  classNameModalButton?: string;
  onSubmit: any;
  onClose: any;
  title?: string;
  isLoading?: boolean;
  defaultMessage?: string;
  className?: string;
}

function ContactForm(props: IContactForm) {
  const { onSubmit, onClose, isLoading, defaultMessage } = props;
  const translation = useTranslations("contactForm");
  const translationError = useTranslations("error");
  const formRef: any = useRef(null);
  const referer: any = useGetPersistParams({ onlyCode: true });
  console.log(referer);
  const validationSchema = yup.object({
    full_name: yup.string().required(translationError("required")),
    phone: yup.number().required(translationError("required")),
    email: yup
      .string()
      .email(translationError("email"))
      .required(translationError("required")),
    message: yup.string().required(translationError("required")),
    // captcha: yup.string().required(translationError("required")),
    subscribe: yup.boolean().required(translationError("required")),
  });

  if (referer?.referer) {
    initialValues.referral_code = referer["referer"];
  }
  if (referer?.areferer) {
    initialValues.agency_referral_code = referer["areferer"];
  }

  return (
    <Formik
      initialValues={{
        ...initialValues,
        message: defaultMessage,
      }}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      enableReinitialize={true}
    >
      {(formikProps) => {
        return (
          <Form className={`${ props.className }-form-hubspot-remaxrd`}>
            <StyledHeader>
              <FlexItem />
              <FlexItem>
                <TitleContainer>
                  <Title>{props?.title}</Title>
                </TitleContainer>
              </FlexItem>
              <FlexItem>
                <Button
                  variant="text"
                  colortext={"--color-gray-02"}
                  onClick={onClose}
                >
                  {translation("close")}
                </Button>
              </FlexItem>
            </StyledHeader>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    name="full_name"
                    label={translation("fullName") + "*"}
                    inputComponent={TextMaskInput}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="phone"
                    label={translation("phone") + "*"}
                    inputComponent={PhoneMask}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField name="email" label={translation("email") + "*"} />
                </Grid>
                <Grid item xs={12}>
                  <TextArea
                    name="message"
                    label={translation("comment") + "*"}
                    multiline
                    minRows={5}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <CheckboxCustom
                    onChange={() => {
                      formikProps.setFieldValue(
                        "subscribe",
                        !formikProps.values.subscribe
                      );
                    }}
                    checked={formikProps.values.subscribe}
                    name="subscribe"
                    style={{
                      display: "inline-block",
                      justifyContent: "flex-start",
                      padding: "5px 0px 9px 9px",
                    }}
                  />
                  <LabelField
                    label={translation("subscribe")}
                    positionLabel="left"
                  />
                </Grid>

                {/* <RecaptchaField formikProps={formikProps} /> */}
              </Grid>
            </DialogContent>
            <StyledActions>
              <Button
                type="submit"
                fs="12px"
                bg="--color-secondary"
                thin_button={true}
                className={props?.classNameModalButton}
                disabled={isLoading}
                loadingMessage={isLoading ? translation("sending") : null}
              >
                {translation("send")}
              </Button>
            </StyledActions>
          </Form>
        );
      }}
    </Formik>
  );
}

export default ContactForm;

const StyledHeader = styled(DialogTitle)`
  text-align: right;
  font-size: 13px;
  color: var(--color-gray-01);
  display: flex;
  @media screen and (max-width: 600px) {
    flex-direction: column-reverse;
  }
`;

const Title = styled.h3`
  color: var(--color-blue-main);
`;

const FlexItem = styled.div`
  flex: 1;
  @media screen and (max-width: 600px) {
    flex-basis: 100%;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-transform: capitalize;
`;

const StyledActions = styled(DialogActions)`
  justify-content: center;
`;

const TextArea = styled(TextField)`
  border-radius: 20px;
`;

const initialValues = {
  full_name: "",
  phone: "",
  email: "",
  message: "",
  captcha: "",
  subscribe: true,
  referral_code: null,
  agency_referral_code: null,
};
