/**
 *
 * @comments
 *  use this format for picture
 *  .webp
 *  .idioms_flag
 *  30px x 20px ~~
 */

export const CIdioms: IIdioms[] = [
  {
    icon: "/icons/dominican-republic-flag.jpg",
    alt: "Bandera República Dominicana",
    link: "/es",
    name: "Español",
    acronym: "ES",
  },
  {
    icon: "/icons/united-states-flag.jpg",
    alt: "Bandera Estados Unidos",
    link: "/en",
    name: "English",
    acronym: "EN",
  },
  // {
  //   icon: "/icons/fr_flag.webp",
  //   alt: "Bandera francia",
  //   link: "/fr",
  // },
];
export interface IIdioms {
  icon: string;
  alt: string;
  link: string;
  name: string;
  acronym: string;
}
