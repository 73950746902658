import { css } from "@emotion/react";
import CircleIcon from "@mui/icons-material/Circle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { Checkbox, CheckboxProps } from "@mui/material";
import { green } from "@mui/material/colors";
import React from "react";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
function CheckboxCustom(props: ICheckBoxComponent) {
  return (
    <div css={styles.checkContent}>
      <Checkbox
        css={styles.Checkbox}
        icon={<CheckBoxOutlineBlankIcon className="unIconChecked" />}
        checkedIcon={<CheckBoxIcon className="iconChecked" />}
        {...props}
      />
    </div>
  );
}


export default CheckboxCustom

const styles = {
  Checkbox: css({
    width: "min-content",
    height: "min-content",
  }),
  checkContent: css({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& .iconChecked": {
      color: green[500]
    },
    "& .unIconChecked": {},
  }),
};
export interface ICheckBoxComponent extends CheckboxProps {}
