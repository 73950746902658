import { getRequest, patchResquest, postRequest } from "api/base-services.request";
import { ClientsEndpoints } from "shared/models/endpoits/clients/clients.endpoints";
import { ClientUser, ClientUserResponseData } from "shared/models/interfaces/client-user.interface";
import {
  IChangePassword,
  IConfirmEmail,
  IForgotPassword,
  IResendConfirmEmail,
  IRestorePassword,
  ISignInForm,
  ISignUpForm,
} from "shared/models/interfaces/clients.interfaces";
import { Response } from "shared/models/interfaces/response/base-response.interface";
import { Country } from "@interfaces/countries.interface";
import { OAuthProvider } from "shared/models/types/oAuth-provider.types";

export function userSignUp(body: ISignUpForm) {
  return postRequest(ClientsEndpoints.register, body).then((res) => res?.data);
}

export function userSignIn(body: ISignInForm): Promise<ClientUserResponseData> {
  return postRequest(ClientsEndpoints.login, body).then(({ data }) => data.data);
}

export function userLogout() {
  return postRequest(ClientsEndpoints.logout, null).then((res) => res?.data);
}

export function userForgotPassword(body: IForgotPassword) {
  return postRequest(ClientsEndpoints.forgotPassword, body).then(
    (res) => res?.data
  );
}

export function userRestorePassword(
  clientId: number | string,
  searchParams: string,
  body: IRestorePassword
) {
  return postRequest(
    ClientsEndpoints.resetPassword(clientId) + searchParams,
    body
  ).then((res) => res?.data);
}

export function userChangePassword(body: IChangePassword) {
  return patchResquest(ClientsEndpoints.changePassword, body).then(({ data }) => data)
}

export function userConfirmEmail(body: IConfirmEmail) {
  return postRequest(ClientsEndpoints.confirmEmail, body).then(
    (res) => res?.data
  );
}

export function userResendConfirmEmail(body: IResendConfirmEmail) {
  return postRequest(ClientsEndpoints.resendConfirmEmail, body).then(
    (res) => res?.data
  );
}

export function loginCallback(provider: OAuthProvider, searchParams: string): Promise<ClientUserResponseData> {
  return getRequest(ClientsEndpoints.loginCallback(provider) + searchParams).then((res) => res?.data.data);
}

export function userUpdateProfile(
  body: any,
  useMultipartFormData: boolean = false
): Promise<ClientUser> {
  return postRequest<Response<ClientUser>>(
    ClientsEndpoints.updateProfile,
    body,
    useMultipartFormData
  ).then(({ data }) => data.data);
}

export function userGetProfile(): Promise<ClientUser> {
  return getRequest<Response<ClientUser>>(ClientsEndpoints.profile).then(({ data }) => data.data);
}

export function getCountries(): Promise<Country[]> {
  return getRequest<Response<Country[]>>(ClientsEndpoints.countries).then(({ data }) => data.data);
}