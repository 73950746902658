import {
  Form,
  Formik,
  Field,
  FieldAttributes,
  useField,
  FormikConfig,
} from "formik";
import * as yup from "yup";
import React, { useState, useRef } from "react";
import { useMutation } from "@tanstack/react-query";
import styled from "@emotion/styled";
import TextField from "shared/components/text-field";
import Button from "shared/components/Button";

import { postSubscription } from "api/newsletter.request";
import { ModalMessage } from "shared/components/message-modal";
import { useTranslations } from "next-intl";
import FormWithHostPost from "@components/form-with-host-post";
import FormHotpots from "shared/models/const/form-hotpots";

const postData = async (email: string) => {
  const response = await postSubscription(email);
  return response;
};

const initialState = {
  body: "",
  title: "",
  isError: false,
};

type State = typeof initialState;

function FormFooter() {
  const [message, setMessage] = useState<State>(initialState);
  const translation = useTranslations("footer.form");
  const translationError = useTranslations("error");
  const [open, setOpen] = useState<boolean>(false);

  const form: FormikConfig<any> = {
    initialValues: {
      email: null,
    },
    onSubmit: (v, formikFh) => {
      mutateAsync(v.email).then(() => {
        formikFh.setValues({ email: "" }, false);
      });
    },
    validationSchema: yup.object({
      email: yup
        .string()
        .email(translationError("email"))
        .required(translationError("required"))
        .nullable(),
    }),
    enableReinitialize: true,
  };
  const translationAlert = useTranslations("alerts.subscription");

  const handleMessage = (data: State) => {
    setOpen(true);
    setMessage(data);
  };

  const { mutateAsync, isLoading } = useMutation(postData, {
    onSuccess: () => {
      handleModal(),
        handleMessage({
          title: translationAlert("titleSuccess"),
          body: translationAlert("bodySuccess"),
          isError: false,
        });
    },
    onError: (e: any) => {
      const title = translationAlert("titleError");
      const isError = true;
      if (e.response.data.code === "exists") {
        handleMessage({
          title: title,
          body: translationAlert("bodyUsedEmail"),
          isError: isError,
        });
      } else if (e.response.data.code == "email") {
        handleMessage({
          title: title,
          body: translationAlert("bodyInvalidEmail"),
          isError: isError,
        });
      } else {
        handleMessage({
          title: title,
          body: translationAlert("bodyError"),
          isError: isError,
        });
      }
    },
  });

  const handleModal = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Formik {...form}>
        {(props) => (
          <CustomForm className="subscribe-blog-hubspot-remaxrd">
            {/* <p className="subscribe">{translation("subscribe")}</p>
            <div className="p-h-5 d-flex">
              <ContentInput >
                <CustomInput name="email" placeholder={translation("placeholder")} ></CustomInput>
              </ContentInput>

              <SubmitButton
                type="submit"
                disabled={isLoading}
                loadingMessage={isLoading ? translation("sending") : null}
              >
                {translation("submit")}
              </SubmitButton>
            </div>
 
            <ModalMessage
              title={message.title}
              body={message.body}
              variant={message.isError ? "error" : undefined}
              open={open}
              handleClose={handleClose}
            /> */}
            <FormWithHostPost {...FormHotpots.SUSCRIBE_BLOG_FOOTER} />
          </CustomForm>
        )}
      </Formik>
    </>
  );
}

export default FormFooter;

const ContentInput = styled.div`
  max-width: 270px;
  width: 100%;
  margin: 0 20px 10px 0;
  display: inline-block;
  @media screen and (max-width: 787px) {
    & .content-input-field {
      display: block !important;
    }
  }
`;

const CustomInput = styled(TextField)`
  background: none;
  outline: none;
  border: 1px solid #fff;
  border-radius: 50px;
  padding: 3px 20px;
  color: #fff;
  font-size: 0.9rem;
  margin: 0 20px 10px 0;
  max-width: 270px;
  width: 100%;
  font-weight: 500;
  // font-family: Arial;
  & input::placeholder {
    color: #fff;
  }
  &:focus,
  &:hover {
    border: 1px solid #fff;
  }
`;

const CustomForm = styled(Form)`
  margin-top: 80px;
  padding: "0px 5px";
  & .subscribe {
    color: white;
    margin: 40px 0;
    padding: 0px 5px;
    color: var(--white);
    font-size: var(--fs-body-smaller);
    font-weight: 400;
    line-height: 15px;
  }
  & button {
    background-color: #fff;
    color: #1b2e57;
    border: none;
    outline: none;
    margin-top: 10px;
    padding: 9px 20px;
    border-radius: 50px;
    cursor: pointer;
    font-style: normal;
    font-weight: 700;
    font-size: 9px;
    line-height: 10px;
    text-align: center;
    color: var(--color-blue-main);
    max-width: 120px;
    min-width: 120px;
    height: 36px;
    vertical-align: top;
    @media screen and (max-width: 991px) {
      & {
        margin-top: 0;
      }
    }
    @media screen and (max-width: 787px) {
      & {
        align-self: flex-start;
        margin-top: 10px;
      }
    }
  }
`;

const Error = styled.div`
  width: 100%;
  color: var(--color-red-error);
  font-weight: 500;
  padding-left: 10px;
  font-size: 0.8125rem;
`;

const SubmitButton = styled(Button)`
  &.Mui-disabled,
  &:hover {
    background-color: #fff;
    color: #1b2e57;
    border: none;
    outline: none;
    padding: 9px 20px;
    border-radius: 50px;
    cursor: pointer;
    font-style: normal;
    font-weight: 700;
    font-size: 9px;
    line-height: 10px;
    text-align: center;
    color: var(--color-blue-main);
    max-width: 120px;
    min-width: 120px;
    height: 36px;
    vertical-align: top;
  }
  @media screen and (max-width: 787px) {
    & {
      align-self: flex-start;
    }
  }
`;
