import querystring from "query-string";

const defaultQueryFormatterOptions: querystring.StringifyOptions = {
  skipEmptyString: true,
  skipNull: true,
  arrayFormat: "bracket",
};

const defaultQueryParserOptions: querystring.ParseOptions = {
  arrayFormat: "bracket",
};

export function queryFormatter(
  data: any,
  options?: querystring.StringifyOptions
) {
  return querystring.stringify(data, {
    ...defaultQueryFormatterOptions,
    ...options,
  });
}

export function queryParser(data: any, options?: querystring.ParseOptions) {
  return querystring.parse(data, { ...defaultQueryParserOptions, ...options });
}

export function queryParserUrl(data: any, options?: querystring.ParseOptions) {
  return querystring.parseUrl(data, {
    ...defaultQueryParserOptions,
    ...options,
  });
}
