import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";

type PropertiesLoading = boolean;

const initialState: boolean = false;
const PropertiesLoadingSlice = createSlice({
  name: "propertiesLoading",
  initialState,
  reducers: {
    setPropertiesLoading: (state: boolean, action: PayloadAction<boolean>) =>{
      return action.payload;
    },
   
    clearPropertiesLoading: () => {
      return false;
    },
  },
});

export const { setPropertiesLoading, clearPropertiesLoading } =
  PropertiesLoadingSlice.actions;
export default PropertiesLoadingSlice.reducer;

// hook
export const usePropertiesLoading = () => {
  const loadingRequest = useSelector(
    (state: any) => state?.propertiesLoading
  ) as boolean;

  const dispatch = useDispatch();

  const setLoadingPropertyAction = (state: PropertiesLoading) => {
    dispatch(setPropertiesLoading(state));
  };

  const clearPropertyAction = () => {
    dispatch(clearPropertiesLoading());
  };

  return {
    loadingRequest,
    clearPropertyAction,
    setLoadingPropertyAction,
  };
};
