import axios from "axios";
import qs from "qs";

const baseURL = process.env.NEXT_PUBLIC_BLOG_API_URL;

export const apiClient = axios.create({
  baseURL: baseURL,
  headers: {
    "Content-type": "application/json",
    // "access-control-allow-origin": "*",
    // "x-requested-with": "XMLHttpRequest",
  },
  paramsSerializer: (params) => qs.stringify(params, { encode: false }),
});

export const postRequest = (
  endPoint: string,
  body: any,
  useFormData?: boolean
) => {
  let formBody = body;

  if (useFormData) {
    formBody = new FormData();

    for (var key in body) {
      formBody.append(key, body[key]);
    }
  }
  return apiClient.post(`${endPoint}`, formBody);
};
