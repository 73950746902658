import React from "react";
import { Dialog, DialogContent, DialogTitle, Grid } from "@mui/material";
import styled from "@emotion/styled";
import SignUpForm from "../signup-form";
import Button from "../Button";
import { useTranslations } from "next-intl";

interface ISignUpDialog {
  open: boolean;
  onClose: () => void;
  openSignIn: () => void;
}

export default function SignUpDialog(props: ISignUpDialog) {
	const { open, onClose, openSignIn } = props;
	const tShared = useTranslations("shared");

	return (
    <>
      <StyledDialog open={open} onClose={onClose}>
        <DialogTitle style={{ paddingBottom: "0" }}>
          <Grid container spacing={1}>
            <Grid item xs={12} textAlign="right">
              <Button
                variant="text"
                colortext={"--color-gray-02"}
                onClick={onClose}
              >
                {tShared("dialog.closeBtn")}
              </Button>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <SignUpForm onSignUpSuccess={onClose} openSignIn={openSignIn} />
        </DialogContent>
      </StyledDialog>
    </>
  );
}

const StyledDialog = styled(Dialog)`
	& .MuiDialog-paper {
    background-color: var(#efeff1);
    border-radius: 20px;
  }
`;