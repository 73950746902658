import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import React from "react";
import { CPagesPath } from "shared/models/const/pages-path";
import styled from "@emotion/styled";

const Logo = React.forwardRef(function FLogo(props: any, ref: React.Ref<any>) {
  const location = useRouter();
  return (
    <div>
      {location.pathname.includes(CPagesPath.es.collection) ? (
        <CollectionLogo ref={ref} />
      ) : (
        <RemaxRdLogo ref={ref} />
      )}
    </div>
  );
});

export default Logo;

const CollectionLogo = React.forwardRef(function FCollection(
  props: any,
  ref: React.Ref<any>
) {
  return (
    <CollectionLogoContent ref={ref}>
      <div className="content-logo">
        <Link href={CPagesPath.es.collection} className="logo" passHref>
          <LogoImage
            src="/images/image-optimize/remax-collection.webp"
            alt="Logo Re/max"
            className="logo_image"
            width={135}
            height={55}
          />
        </Link>
      </div>
    </CollectionLogoContent>
  );
});

const RemaxRdLogo = React.forwardRef(function FRemax(
  props: any,
  ref: React.Ref<any>
) {
  return (
    <Link href="/" className="logo__link" ref={ref} passHref>
      <LogoImage
        src="/icons/remax-rd.png"
        alt="Logo Re/max"
        className="logo_image"
        width={150}
        height={45}
      />
    </Link>
  );
});

const CollectionLogoContent = styled.div`
  position: relative;
  width: 150px;
  align-self: flex-start;
  .content-logo {
    position: absolute;
    padding: 0px 10px;
    top: -32px;
    left: 0;
    width: 100%;
    height: 100px;
    background: var(--color-blue-main-collector);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0 0 20px 20px;
  }
`;
// const collection

const LogoImage = styled(Image)`
  cursor: pointer;
`;
