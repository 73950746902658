import React from "react";
import {
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  Grid,
} from "@mui/material";
import styled from "@emotion/styled";
import ForgotPasswordForm from "../forgot-password-form";
import Button from "../Button";
import { useTranslations } from "next-intl";
import ReturnButton from "@components/buttons/return-button";
import { useAppDispatch } from "custom-hooks/redux.hooks";
import { openAuthDialog } from "store/feature/auth/auth-dialog.slice";

interface IRestorePasswordDialog {
  open: boolean;
  onClose: () => void;
}

export default function RestorePasswordDialog({
  open,
  onClose,
}: IRestorePasswordDialog) {
  const tShared = useTranslations("shared");
  const dispatch = useAppDispatch();

  let configProp: DialogProps = {
    open,
    onClose,
    fullWidth: true,
    maxWidth: "sm",
  };

  return (
    <>
      <StyledDialog {...configProp}>
        <DialogTitle style={{ paddingBottom: "0" }}>
          <Grid container spacing={1}>
            <Grid container item xs={12} justifyContent="space-between">
              <ReturnButton
								aria-label="return to login"
                onClick={() => dispatch(openAuthDialog("signin"))}
              />
              <Button
                variant="text"
                colortext={"--color-gray-02"}
                onClick={onClose}
              >
                {tShared("dialog.closeBtn")}
              </Button>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <ForgotPasswordForm onClose={onClose} />
        </DialogContent>
      </StyledDialog>
    </>
  );
}

const StyledDialog = styled(Dialog)`
  & .MuiDialog-paper {
    background-color: var(#efeff1);
    border-radius: 20px;
  }
`;
