import { MessagesEndpoint } from 'shared/models/endpoits/messages.endpoint'
import { IMessagesResponse } from 'shared/models/interfaces/response/messages.interface'

import { postRequest } from './base-services.request'

export interface IMessageContact {
  full_name: string;
  phone: string;
  email: string;
  message: string;
}

export interface IFranchiseMessage {
  full_name: string;
  email: string;
  phone: string;
  address: string;
  birthdate: any;
  "g-recaptcha-response": string;
  subscribe: boolean;
}

export interface ILoan {
  full_name: string;
  nationality: string;
  cedula: string;
  phone: string;
  email: string;
  current_country_residence: string;
  initial_payment_value: string;
  amount_finance: string;
  comment: string;
  cell_phone: string;
  realestate_id: string;
  agent?: string;
  birthdate: string
  agency_id?: string;
  "g-recaptcha-response": string;
}

export interface IMessageAgent {
  realstate_id?: number;
  agent_id?: number;
  agency_id?: number;
  permalink?: string | null | undefined;
  client_name: string;
  client_email: string;
  client_phone: string;
  client_message: string;
  "g-recaptcha-response": string;
  subscribe: boolean;
}

export const postMessageContact = async (
  data: IMessageContact
): Promise<IMessagesResponse> => {
  return postRequest(MessagesEndpoint.Contact, data).then(
    (response) => response.data
  );
};

export const postFranchiseMessage = async (
  data: IFranchiseMessage
): Promise<IMessagesResponse> => {
  return postRequest(MessagesEndpoint.Franchise, data).then(
    (response) => response.data
  );
};

export const postLoanRequest = async (
  data: ILoan
): Promise<IMessagesResponse> => {
  return postRequest(MessagesEndpoint.Loan, data).then(
    (response) => response.data
  );
};

export const postMessageAgent = async (
  data: IMessageAgent
): Promise<IMessageAgent> => {
  const id = data?.agent_id ?? data?.agency_id;
  return postRequest(MessagesEndpoint.Agent(id!), data).then(
    (response) => response.data
  );
};
