import React from "react";
import NumberFormat from "react-number-format";
import MaskedInput from "react-text-mask";

export const NumberInput = React.forwardRef(function NumberInput(
  props: any,
  ref
) {
  const { onChange = () => {}, ...other } = props;

  return (
    <NumberFormat
      {...other}
      onValueChange={(values) => {
        onChange(values.value);
      }}
      getInputRef={ref}
    />
  );
});

export const PercentInput = React.forwardRef(function NumberInput(
  props: any,
  ref
) {
  const { onChange = () => {}, ...other } = props;

  return (
    <NumberFormat
      {...other}
      suffix="%"
      onValueChange={(values) => {
        onChange(values.value);
      }}
      getInputRef={ref}
    />
  );
});

export const MoneyInput = React.forwardRef(function MoneyInput(
  props: any,
  ref
) {
  return (
    <NumberInput
      prefix={"$"}
      thousandSeparator
      allowNegative={false}
      ref={ref}
      {...props}
    />
  );
});

export const PhoneMask = React.forwardRef(function PhoneMask(props: any, ref) {
  const { onChange = () => {}, ...rest } = props;

  return (
    <NumberFormat
      format={props.format ?? "(###) ###-####"}
      mask="_"
      allowNegative={false}
      onValueChange={(values) => {
        onChange(values.value);
      }}
      getInputRef={ref}
      {...rest}
    />
  );
});

export const CedulaMask = React.forwardRef(function CedulaMask(
  props: any,
  ref
) {
  const { onChange = () => {}, ...rest } = props;

  return (
    <NumberFormat
      format="###-#######-#"
      mask="_"
      allowNegative={false}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange(values.value);
      }}
      {...rest}
    />
  );
});

export const TextMaskInput = React.forwardRef(function TextMaskInput(
  props: any,
  ref
) {
  return (
    <MaskedInput
      mask={(s) => Array.from(s).map(() => /[a-z\s]/i)}
      guide={false}
      getInputRef={ref}
      {...props}
    />
  );
});
