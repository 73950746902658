import Link from "next/link";
import React, { useState } from "react";
import { CPagesPath } from "shared/models/const/pages-path";
import styled from "@emotion/styled";

import ContactDialog from "shared/components/contact-dialog";
import { useTranslations } from "next-intl";

function NavLinks() {
  const [open, setOpen] = useState<boolean>(false);
  const translation = useTranslations("footer.links");

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const CNavLinksFooter = [
    {
      slug: translation("properties"),
      path: CPagesPath.es.properties,
      target: "",
    },
    {
      slug: translation("agencies"),
      path: CPagesPath.es.agencies_list,
      target: "",
    },
    {
      slug: translation("franchise"),
      path: CPagesPath.es.franchises,
      target: "",
    },
    {
      slug: translation("mortgage"),
      path: CPagesPath.es.loan_mortgage,
      target: "",
    },
    {
      slug: translation("blog"),
      path: CPagesPath.blog,
      target: "_blank",
    },
    {
      slug: translation("collection"),
      path: CPagesPath.es.collection,
      target: "",
    },
    {
      slug: translation("contact"),
      path: "",
      target: "",
    },
    // {
    //   slug: translation("buy"),
    //   path: CPagesPath.es.project,
    //   target: "",
    // },
    {
      slug: translation("terms"),
      path: CPagesPath.es.termsAndCondition,
      target: "",
    },
    {
      slug: translation("privacy"),
      path: CPagesPath.es.privacyPolitics,
      target: "",
    },
  ];

  return (
    <FooterNav>
      <ul>
        {CNavLinksFooter.map((item, i) => {
          if (item.slug === translation("contact")) {
            return (
              <li key={i}>
                <LinkBtn onClick={handleOpen}>{item.slug}</LinkBtn>
              </li>
            );
          }

          return (
            <li key={i}>
              <Link href={item.path} passHref>
                <a target={item?.target}>{item.slug}</a>
              </Link>
            </li>
          );
        })}
      </ul>
      <ContactDialog 
        open={open} 
        onClose={handleClose} 
        classNameModalButton="enviar-formulario-contacto-menu" />
    </FooterNav>
  );
}

export default NavLinks;

const FooterNav = styled.nav`
  padding-top: 50px;
  padding-bottom: 20px;
  ul {
    text-align: center;
  }
  li {
    display: inline-block;
    margin: 0 15.5px;
    padding: 5px 0;
    margin-bottom: 5px;
  }
  a {
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 13px;
    text-align: center;
    color: white;
  }
`;

const LinkBtn = styled.a`
  cursor: pointer;
`;
