import React from "react";
import { useSnackbar, SnackbarMessage, OptionsObject } from "notistack";

const defaultOptions: OptionsObject = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "right",
  },
  autoHideDuration: 3000,
};

function useSnackBar() {
  const { enqueueSnackbar, ...rest } = useSnackbar();

  const showMessage = (message: SnackbarMessage, options?: OptionsObject) => {
    enqueueSnackbar(message, { ...defaultOptions, ...options });
  };

  return { showMessage, ...rest };
}

export default useSnackBar;
