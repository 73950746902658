import { IRealestateResponseData } from "../interfaces/response/realestate.interface";

const UniversalLinks = {
  blog: "https://blog.remaxrd.com/",
};

const es = {
  home: "/",
  collection: "/collection",
  collection_properties: "/collection/propiedades",
  collection_agents_list: "/collection/agentes",

  collection_property_detail: (x: IRealestateResponseData) =>
    `/collection/propiedad/${RealestateEncode(x.realstate_type)}/${x.slug}`,

  collection_agent_detail: (x: IAgentDataParam) =>
    `/collection/agente/${HEncodeString(x.name)}/${x.id}`,

  properties: "/propiedades",

  shop: "/propiedades/comprar",
  acquire: "/propiedades/alquilar",
  property_detail: (x: IRealestateResponseData) =>
    `/propiedad/${RealestateEncode(x.realstate_type)}/${x?.slug}`,

  project: "/proyectos",
  project_Detail: (x: IRealestateResponseData) => `/proyecto/${x.slug}`,

  loan_form: "/prestamos/formulario",
  loan_mortgage: "/prestamos/hipotecarios",
  loan_calculator: "/prestamos/calculadora",

  franchises: "/franquicias",
  agents_list: "/listado/agentes",
  agent_detail: (x: IAgentDataParam) =>
    `/agente/${HEncodeString(x.name)}/${x.id}`,

  agencies_list: "/listado/agencias",
  agency_detail: (x: { name: string; id: number }) =>
    `/agencia/${HEncodeString(x.name)}/${x.id}`,

  frequent_questions: "/preguntas-frecuentes",
  join: "/unete",
  termsAndCondition: "/terminos-condiciones",
  privacyPolitics: "/politica-privacidad",
  signin: "/acceder",
  profile: "/perfil",
  profileEditar: "/perfil/configuracion/editar-perfil",
  profileCambiarClave: "/perfil/configuracion/cambiar-clave",
  profileFavorites: "/perfil/configuracion/favoritos",
  ProfileSearchHistory: "/perfil/configuracion/busquedas",
  ProfileLastedSee: "/perfil/configuracion/historial-busquedas",
  signup: "/registrate",
  forgotPassword: "/restaurar-clave"
};

export const CPagesPath = {
  ...UniversalLinks,
  es,
};

interface IAgentDataParam {
  name: string;
  id: number;
}

const HEncodeString = (x: string) => 
  `${x.replace(/\//g, "-").split(" ").join("-").toLowerCase()}`;

const RealestateEncode = (x: string) =>
  x?.replaceAll("/", "o")?.replaceAll(" ", "-").toLowerCase();