import React from "react";
import { Dialog, DialogContent, DialogTitle, Grid } from "@mui/material";
import styled from "@emotion/styled";
import SignInForm from "../signin-form";
import Button from "../Button";
import { useTranslations } from "next-intl";
import { useAppDispatch } from "custom-hooks/redux.hooks";
import { closeAuthDialog } from "store/feature/auth/auth-dialog.slice";

interface ISignInDialog {
  open: boolean;
  onClose: () => void;
  openSignUp: () => void;
  openRestorePassword: () => void;
}

export default function SignInDialog(props: ISignInDialog) {
  const { open, onClose, openSignUp, openRestorePassword } = props;
  const tShared = useTranslations("shared");
  const dispatch = useAppDispatch();

  const onLoginSuccess = () => {
    dispatch(closeAuthDialog());
  };

  return (
    <>
      <StyledDialog open={open} onClose={onClose}>
        <DialogTitle>
          <Grid container spacing={1}>
            <Grid item xs={12} textAlign="right">
              <Button
                variant="text"
                colortext={"--color-gray-02"}
                onClick={onClose}
              >
                {tShared("dialog.closeBtn")}
              </Button>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <SignInForm
            {...{
              openSignUp,
              openRestorePassword,
              onLoginSuccess,
            }}
          />
        </DialogContent>
      </StyledDialog>
    </>
  );
}

const StyledDialog = styled(Dialog)`
  & .MuiDialog-paper {
    background-color: var(#efeff1);
    border-radius: 20px;
  }
`;
