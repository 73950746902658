import { useState } from "react";
import TextField from ".";
import { ITextFieldComponent } from "./default-text-field";
import { TogglePasswordIcon } from "./password-field-toggle";

export default function PasswordField(
  props: Omit<ITextFieldComponent, "type">
) {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <TextField
      {...props}
      type={showPassword ? "text" : "password"}
      endAdornment={
        <TogglePasswordIcon
          {...{
            showPassword,
            handleToggle: () => setShowPassword(!showPassword),
          }}
        />
      }
    />
  );
}
