import Head from "next/head";
import { platform } from "os";
import React from "react";

const isApplePlatform = ['iPhone', 'iPod', 'iPad', 'iPhone Simulator', 'iPod Simulator', 'iPad Simulator', 'Macintosh', 'MacIntel', 'MacPPC', 'Mac68K', 'Pike v7.6 release 92', 'Pike v7.8 release 517'];

function FontLoader() {
  const os = typeof window !== "undefined" ? window.navigator.platform : null;

  if(os == null) return null;

  if(isApplePlatform.includes(os)) {
    return <MacGlobalStyles/>
  } else {
    return <></>
  }
}

export default FontLoader;

function MacGlobalStyles(): React.ReactElement {
  return (
    <style jsx global>{`
      :root {
       --font-family: "Gotham-mac" !important;
      }
    `}</style>
  );
}
