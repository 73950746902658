import { useEffect } from "react";
import { useRouter } from "next/router";
import { useSessionStorage } from "usehooks-ts";
import { isEmpty, isEqual } from "lodash";

import {
  queryParserUrl,
  queryFormatter,
} from "shared/models/functions/query-string-helper";

function usePersistParams() {
  const [code, setCode] = useSessionStorage("code", { id: null, type: null });
  const router = useRouter();

  const handleStringify = (type) => {
    if (type === "referer") {
      return queryFormatter({ referer: code.id });
    }

    return queryFormatter({ areferer: code.id });
  };

  useEffect(() => {
    const { query } = queryParserUrl(window.location.href);
    let data = {
      id: query.referer || query.areferer,
      type: "areferer",
    };
    
    if (Boolean(query.referer)) {
      data.type = "referer";
    }

    if (!isEqual(data, code) && Boolean(data.id) && Boolean(data.type)) {
      setCode(data);
    }

    if (!Boolean(data.id) && Boolean(code.id)) {
      const queryStrings = isEmpty(query)
        ? handleStringify(code.type)
        : queryFormatter(query) + "&" + handleStringify(code.type);

      const pathName = window.location.pathname + "?";

      const url = pathName + queryStrings;

      router.replace(url, undefined, {
        shallow: true,
      });
    }
  }, [router.asPath, code.id, code.type, router.query]);

  return null;
}

export default usePersistParams;
