import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { userLogout } from "api/clients/clients.request";
import { ClientUser } from "shared/models/interfaces/client-user.interface";
import { getUserToken, userState } from "shared/services/auth.service";
import { AppThunk } from "store";

const initialState = {
  token: getUserToken(),
  user: userState(),
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUser: (state, { payload }: PayloadAction<ClientUser>) => {
      localStorage.setItem("user", JSON.stringify(payload));
      state.user = payload;
    },
    setToken: (state, { payload }: PayloadAction<string>) => {
      localStorage.setItem("token", payload);
      state.token = payload;
    },
    clearAuth: (state) => {
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      state.user = null;
      state.token = null;
    }
  }
});

export const { setUser, setToken, clearAuth } = authSlice.actions;

export const logout = (): AppThunk<void> => {
  return (dispatch) => {
    userLogout().then(() => {
      dispatch(clearAuth());
    });
  }
}

export default authSlice.reducer;
