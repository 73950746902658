import { Grid } from "@mui/material";
import React, { useEffect } from "react";

interface IFormWithHostPost {
  targetId: string;
  formId: string;
  onFormSubmitSuccess?: () => void;
  onFormSubmitError?: () => void;
}
function FormWithHostPost(props: IFormWithHostPost) {
  const { targetId, formId, ...rest } = props;
  const config = {
    region: "na1",
    portalId: "24346264",
    formId: formId,
    target: `#${targetId}`,
    onFormSubmit: function ($form: any) {
      // if (props.onFormSubmitSuccess) {
      //   // setTimeout(() => {
      //   //   props.onFormSubmitSuccess();
      //   // }, 1000);
      // }
    },
    onFormError: function (errors: any) {
      if (props.onFormSubmitError) {
        props.onFormSubmitError();
      }
    },
    onFormSubmitted: function ($form: any) {
      if (props.onFormSubmitSuccess) {
        props.onFormSubmitSuccess();
      }
    }
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "//js.hsforms.net/forms/embed/v2.js";
    script.async = true;
    document.body.appendChild(script);

    script.addEventListener("load", () => {
      // window.addEventListener("message", (event) => {
      //   if (
      //     event.data.type === "hsFormCallback"  &&  
      //     event.data.eventName === "onFormSubmitted"
      //   ) {
      //     console.log(`Form Submitted! Event data: `,event.data);
      //   }
      // });
      // @ts-ignore
      if (window.hbspt) {
        // @ts-ignore
        window.hbspt.forms.create(config);
      }
    });
    // addEventListener on id=props.targetId to listen for form messages
    // @ts-ignore

    return () => {
      // Cleanup function
      document.body.removeChild(script);
    };
  }, []);

  return (
    <Grid container item xs={12}>
      <Grid item xs={12} id={props.targetId} />
    </Grid>
  );
}

export default FormWithHostPost;
// FormWithHostPost;

