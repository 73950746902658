import { NewsletterEndpoints } from "shared/models/endpoits/newsletter.endpoint";
import { postRequest } from "./blog-services.request";
import { INewsletterResponse } from "shared/models/interfaces/response/newsletter.interface";

export const postSubscription = async (
  email: string
): Promise<INewsletterResponse> => {
  return postRequest(NewsletterEndpoints.Subscription, {
    email,
    lists: [2],
  }).then((response) => response.data);
};
