import React from "react";
import MuiButton, { ButtonProps, ButtonTypeMap } from "@mui/material/Button";
import { css } from "@emotion/react";
import CircularProgress from "@mui/material/CircularProgress";

const Button = (
  { loadingMessage, thin_button, ...props }: ButtonInterface,
  ref: React.Ref<unknown>
) => {
  const backgroundColor =
    props?.variant != "text"
      ? `var(${props?.bg ?? "--color-secondary"})`
      : "transparent";
  const styles = {
    root: css({
      boxShadow: "none",
      transition: "0.5s",
      fontFamily: "var(--font-family)",
      fontWeight: 700,
      fontSize: props.fs ?? 12,
      padding: thin_button ? "7px 70px 7px 70px" : "10px 35px 10px 35px",
      color: `var(${props.colortext})` ?? `var(--white)`,
      borderRadius: 28,
      textAlign: "center",
      backgroundColor,
      ":hover": {
        backgroundColor,
        opacity: 0.9,
        scale: "1.05",
        transition: "1s",
      },
    }),
  };
  return (
    <>
      <MuiButton target={props?.target} variant="contained" css={styles.root} {...props}>
        {loadingMessage ?? props.children}
        {loadingMessage && (
          <CircularProgress
            style={{ width: 20, height: 20, color: "red", marginLeft: 10 }}
          />
        )}
      </MuiButton>
    </>
  );
};

export interface ButtonInterface extends ButtonProps {
  bg?:
    | "--color-secondary"
    | "--color-blue-medley"
    | "--color-blue-light"
    | "--color-blue-main"
    | "--color-green-success"
    | "--color-gray-02"
    | "transparent";
  colortext?:
    | "--color-secondary"
    | "--color-blue-medley"
    | "--color-blue-light"
    | "--color-blue-main"
    | "--color-green-success"
    | "--color-gray-02";

  fs?: "12px" | "15px";
  variant?: "text" | "outlined" | "contained";
  thin_button?: boolean;
  component?: any;
  loadingMessage?: string | null;
  target?: string;

}
export default React.forwardRef(Button);
