import { ArrowBack } from "@mui/icons-material";
import { IconButton, IconButtonProps } from "@mui/material";

export default function ReturnButton(props: IconButtonProps) {
  const { ["aria-label"]: ariaLabel, ...rest } = props;

  return (
    <IconButton {...rest} aria-label={ariaLabel ?? "return"}>
      <ArrowBack />
    </IconButton>
  );
}
