import axios from "axios";
import qs from "qs";
import { createFormData } from "shared/models/functions/create-formdata.helper";
import { getUserToken } from "shared/services/auth.service";

export const baseURL = process.env.NEXT_PUBLIC_API_URL;
const apiKey: any = process.env.NEXT_PUBLIC_API_KEY + "$2";

export const apiClient = axios.create({
  baseURL: baseURL,
  headers: {
    "Content-type": "application/json",
    "access-control-allow-origin": "*",
    "x-api-key": apiKey,
    "x-requested-with": "XMLHttpRequest",
  },
  paramsSerializer: (params) => qs.stringify(params, { encode: false }),
});

export const getRequest = <T = any>(endPoint: string, filters?: Object) => {
  const headers = getAuthHeader();
  return apiClient.get<T>(`${endPoint}`, { params: filters, headers })
};

export const postRequest = <T = any>(
  endPoint: string,
  body: any,
  useFormData?: boolean
) => {
  let formBody = body;
  const headers = getAuthHeader();

  if (useFormData) formBody = createFormData(body);

  return apiClient.post<T>(`${endPoint}`, formBody, {
    headers,
  });
};

export const putRequest = (endPoint: string, body: any) => {
  return apiClient.post(`${endPoint}`, body);
};

export const deleteRequest = (endPoint: string) => {
  const headers = getAuthHeader();
  return apiClient.delete(endPoint, { headers });
};

export const getFileRequest = (endPoint: string) => {
  return apiClient.get(`${endPoint}`, { responseType: "blob" });
};

export const patchResquest = <T = any>(endPoint: string, body: any) => {
  const headers = getAuthHeader();
  return apiClient.patch<T>(endPoint, body, { headers });
};

function getAuthHeader() {
  const token = getUserToken();
  return token ? { Authorization: "Bearer " + token } : undefined;
}