import React from "react";
import styled from "@emotion/styled";
import CancelIcon from "@mui/icons-material/Cancel";
import { useShowNews } from "shared/context/news.context";
import { useRouter } from "next/dist/client/router";
function News() {
  const { showNews, onToggle, isCloseForUser, setCloseForUser } = useShowNews();
  const router = useRouter();

  const handleClose = () => {
    onToggle(false);
    setCloseForUser(true)
  };

  // React.useEffect(() => {
  //   const hasValidReferer =
  //     router.asPath.includes("areferer=55") ||
  //     !router.asPath.includes("referer") 

  //   if (hasValidReferer && !isCloseForUser) {
  //     setTimeout(() => {
  //       onToggle(true);
  //     }, 2000);
  //   } else {
  //     onToggle(false);
  //   }
  // }, [router.asPath, onToggle]);

  return (
    <>
      {showNews && (
        <ContentNews>
          <div className="content-info">
            <h1>
              Gran lanzamiento del proyecto <span>AVIA 3</span>
            </h1>
            <a
              target="_blank"
              rel="noreferrer"
              id="ga-avia-03"
              href="https://torreavia.com/lanzamiento-evento-avia-3/?utm_source=remaxrd&utm_medium=cpc&utm_campaign=cintillo_remax"
            >
              Regístrate aquí
            </a>
          </div>
          <CloseIcon onClick={handleClose} />
        </ContentNews>
      )}
    </>
  );
}

export default News;

const CloseIcon = styled(CancelIcon)`
  color: #fff;
  cursor: pointer;
  @media (max-width: 768px) {
    padding-right:4px;
  }
`;

// const Spacing = styled.div`
//   flex: 1;
// `;
const ContentNews = styled.div`
  background-color: #004c9a;
  height: 48px;
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 5%;
  .content-info {
    color: #fff;
    font-size: 15px;
    gap: 5px;
    font-family: var(--font-family);
    font-weight: 500;
    display: flex;
    width: 95%;
    text-align: center;
    justify-content: center;
    @media (max-width: 768px) {
      flex-direction: column;
    }
    h1 {
      font-size: 15px;
      font-weight: 700;

      span {
        font-weight: 900;
      }

      @media (max-width: 768px) {
        width: 100%;
      }
    }

    a {
      font-size: 14px;
      font-weight: 400;
      color: white;
      text-decoration: underline;
      @media (max-width: 768px) {
        width: 100%;
      }
    }
  }

  header {
    background: #004c9a;
  }
`;
