import { OAuthProvider } from "shared/models/types/oAuth-provider.types";

enum ClientsStatic {
  /**
   * @member {string}
   * @method POST
   * @version 2.0
   * @description Register client user.
   */
  register = "/v2/clients/sign-up",
  /**
   * @member {string}
   * @method POST
   * @version 2.0
   * @description Login client user.
   */
  login = "/v2/clients/login",
  /**
   * @member {string}
   * @method POST
   * @version 2.0
   * @description Login client user.
   */
  logout = "/v2/clients/logout",
  /**
   * @member {string}
   * @method POST
   * @version 2.0
   * @description Send email to recover password.
   */
  forgotPassword = "/v2/clients/send-forgot-pasword",
  /**
   * @member {string}
   * @method PATCH
   * @version 2.0
   * @description User change password.
   */
  changePassword = "/v2/clients/change-password",
  /**
   * @member {string}
   * @method POST
   * @version 2.0
   * @description Confirm email.
   */
  confirmEmail = "/v2/clients/confirm-email",
  /**
   * @member {string}
   * @method GET
   * @version 2.0
   * @description Get profile.
   */
  resendConfirmEmail = "/v2/clients/resend-confirm-email",
  /**
   * @member {string}
   * @method GET
   * @version 2.0
   * @description Get profile.
   */
  profile = "/v2/clients",
  /**
   * @member {string}
   * @method POST
   * @version 2.0
   * @description Update profile.
   */
  updateProfile = "/v2/clients/edit?_method=PUT",
  /**
   * @member {string}
   * @method GET
   * @version 2.0
   * @description Get countries list.
   */
  countries = "/v2/clients/countries",
}

const ClientsDynamic = {
  /**
   * @member {string}
   * @method POST
   * @version 2.0
   * @description Send new password.
   */
  resetPassword: (userId: number | string) => "/v2/clients/reset-password/" + userId,
  /**
   * @member {string}
   * @method GET
   * @version 2.0
   * @description Send search params to callback for OAuth.
   */
  loginCallback: (provider: OAuthProvider) => `/v2/clients/login/${provider}/callback`,
};

export const ClientsEndpoints = {
  ...ClientsDynamic,
  ...ClientsStatic,
};

export type ClientsEndpoints = keyof typeof ClientsEndpoints;
