import Link from "next/link";
import React from "react";
import { CPagesPath } from "shared/models/const/pages-path";

import styled from "@emotion/styled";
import PowerBy from "./components/power-by";
import NavLinks from "./components/nav-links";
import SocialLinks from "./components/social-links";
import Image from "next/image";
import FormFooter from "./components/form";
import { useTranslations } from "next-intl";

function Footer() {
  const translation = useTranslations("footer");
  return (
    <FooterRoot>
      <FooterContainer>
        <FooterRow className="container">
          <ContainerCol className="--left">
            <RemaxLogo>
              <Image
                width={104}
                height={114}
                src="/icons/ballow.svg"
                alt="Logo Remax"
              />
            </RemaxLogo>
            <ColLeftDescription>
              <ContainerButtonLeft>
                <Link href={CPagesPath.es.franchises} passHref>
                  <ButtonCustomize className="--blue">
                    {translation("openFranchise")}
                  </ButtonCustomize>
                </Link>
                <Link href={CPagesPath.es.join} passHref>
                  <ButtonCustomize className="--blue-light m-l-15">
                    {translation("joinRemax")}
                  </ButtonCustomize>
                </Link>
              </ContainerButtonLeft>

              <TextDescription>{translation("about")}</TextDescription>
              <SocialLinks />
            </ColLeftDescription>
          </ContainerCol>

          <ContainerCol className="--right">
            <Link href={CPagesPath.es.frequent_questions} passHref>
              <ButtonCustomize className="--red">
                {translation("faq")}
              </ButtonCustomize>
            </Link>
            <TextFeature>{translation("register")}</TextFeature>
            <FormFooter />
          </ContainerCol>
        </FooterRow>
        <NavLinks />
      </FooterContainer>

      <PowerBy />
    </FooterRoot>
  );
}

export default Footer;

const TextFooter = {
  description_remax: `
     RE/MAX es una empresa multinacional que permite capacitar, educar y relaciona a los asesores y gestores de negocios con el mercado inmobiliario del Mundo, a través de su conocimiento y relaciones nacionales y extranjeras. Desde la primer oficina que abrió sus puertas en 1973 en Denver, Colorado, RE/MAX se ha convertido en una red global de bienes raíces de oficinas de propiedad con franquicias que funciona con más de 145.000 asociados y agentes.
  `,
  feature_text: `
   Mejora tu experiencia de búsqueda. Regístrate para que puedas
  guardar las propiedades de tu interés y recibir actualizaciones
  de las mismas.`,
};

const FooterContainer = styled.div`
  background-color: #1f3564;
  border-radius: 0 200px 0 0;
  @media screen and (max-width: 767px) {
    border-radius: 0;
  }
`;
const FooterRoot = styled.footer`
  background-color: #fff;
`;

const FooterRow = styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 50px;
`;

const RemaxLogo = styled.div`
  width: 20%;
  display: flex;
  align-items: center;
  margin-top: -30px;
  img {
    width: 100%;
    max-width: 130px;
  }
  @media screen and (max-width: 767px) {
    text-align: center;
    margin-bottom: 30px;
    width: 100%;
    display: flex;
    justify-content: center;
    .img {
      margin: 0 auto;
    }
  }
`;

const ButtonCustomize = styled.a`
  min-width: 136px;
  white-space: nowrap;
  display: inline-block;
  padding: 10px 20px;
  color: white;
  border-radius: 20px;
  font-family: var(--font-family);
  font-size: 0.688rem;
  font-weight: 700;
  line-height: 12px;
  text-align: center;
  &.--blue-light {
    background-color: #29a9e0;
  }
  &.--blue {
    background-color: #0071ba;
  }
  &.--red {
    background-color: #e92027;
    margin-top: 15px;
  }
`;

const ContainerButtonLeft = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 23px;
  @media screen and (max-width: 787px) {
    justify-content: space-evenly;
    width: 100%;
    color: white;
  }
`;

const ColLeftDescription = styled.div`
  width: 80%;
  padding: 10px;
  @media screen and (max-width: 767px) {
    text-align: center;
    width: 100%;
  }
`;

const TextDescription = styled.p`
  color: var(--white);
  font-size: var(--fs-body-smaller);
  font-weight: 400;
  line-height: 15px;
`;

const TextFeature = styled.p`
  color: var(--white);
  font-size: var(--fs-body-smaller);
  font-weight: 400;
  line-height: 15px;

  margin: 21px 0px 80px 0px;
`;

const ContainerCol = styled.div`
  width: 50%;
  max-width: 550px;

  &.--left {
    display: flex;
  }

  @media screen and (max-width: 787px) {
    width: 100%;
    margin: 0 auto;
    &.--left {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    &.--right {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      p {
        width: 80%;
        margin: 30px auto;
      }
      form {
        margin: 0 auto;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        div {
          display: flex;
          justify-content: center;
          align-items: center;
          input {
            width: 100%;
          }
        }
      }
    }
  }
`;
