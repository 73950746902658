import React from "react";
import {
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  Grid,
} from "@mui/material";
import styled from "@emotion/styled";
import { useTranslations } from "next-intl";
import CompleteSignUpForm from "shared/components/forms/complete-signup-form";
import { css } from "@emotion/css";

interface IRestorePasswordDialog {
  open: boolean;
}

export default function CompleteSignUpDialog(props: IRestorePasswordDialog) {
  const { open } = props;
  const t = useTranslations("CompleteSignUpDialog");

  let configProp: DialogProps = {
    open,
    fullWidth: true,
    maxWidth: "md",
  };

  return (
    <>
      <StyledDialog {...configProp}>
        <DialogTitle sx={{ mt: 2 }}>
          <Grid container>
            <Grid item xs={12} textAlign="center" margin="auto">
              <h3 css={styles.title}>
                <BoldTitle>{t("header.title")}</BoldTitle>
              </h3>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid
            item
            xs={12}
            mb={2}
            textAlign="center"
            className={messageContainer}
          >
            <p>
              {t("message.firstp")}
              <br />
              <br />
              {t("message.secondp")}
            </p>
          </Grid>
          <CompleteSignUpForm />
        </DialogContent>
      </StyledDialog>
    </>
  );
}

const StyledDialog = styled(Dialog)`
  & .MuiDialog-paper {
    background-color: var(#efeff1);
    border-radius: 20px;
  }
`;

const BoldTitle = styled.span`
  font-weight: 700;
  text-transform: uppercase;
`;

const messageContainer = css`
  font-size: 12px;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
`;

const styles = {
  title: {
    marginBottom: 12,
  },
};
