
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AuthDialogName } from "shared/models/types/auth-dialog-name.type";

const initialState: { dialog: AuthDialogName } = {
  dialog: null
};

export const authDialogSlice = createSlice({
  name: "auth-dialog",
  initialState,
  reducers: {
    open: (state, { payload }: PayloadAction<AuthDialogName>) => {
      state.dialog = payload;
    },
    close: (state) => {
      state.dialog = null
    }
  }
});

export const { open: openAuthDialog, close: closeAuthDialog } = authDialogSlice.actions;
export default authDialogSlice.reducer;
