import React, { useEffect, useState } from "react";
import { css } from "@emotion/css";
import styled from "@emotion/styled";
import Logout from "@mui/icons-material/Logout";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { useAppDispatch, useAppSelector } from "custom-hooks/redux.hooks";
import { logout } from "store/feature/auth/auth.slice";
import { CPagesPath } from "shared/models/const/pages-path";
import { openAuthDialog } from "store/feature/auth/auth-dialog.slice";
import { useTranslations } from "next-intl";
import ProfilePicture from "../profile-picture";

export default function DesktopSignInButton() {
  const router = useRouter();
  const [hiddenButton, setHiddenButton] = useState(false);
  const { user } = useAppSelector((state) => state.Auth);
  const dispatch = useAppDispatch();
  const t = useTranslations("header");

  const handleLogout = (e: React.MouseEvent) => {
    e.stopPropagation();
    dispatch(logout());
  };

  useEffect(() => {
    //REFACTOR!
    const { signin, signup, forgotPassword } = CPagesPath.es;
    const inForbiddenRoute = [signin, signup, forgotPassword].includes(
      router.asPath
    );
    setHiddenButton(inForbiddenRoute);
  }, [router.asPath]);

  if (hiddenButton) return null;

  if (user) {
    return (
      <Link href={CPagesPath.es.profile}>
        <StyledUserProfileLink>
          <ProfilePicture size={40} src={user.profile_picture} />
          <p className="truncate">{user.full_name}</p>
          <Logout className="logout-button" onClick={handleLogout} />
        </StyledUserProfileLink>
      </Link>
    );
  }

  return (
    <StyledButton onClick={() => dispatch(openAuthDialog("signup"))}>
      {t("user-signup")}
    </StyledButton>
  );
}

const StyledButton = styled.a`
  background-color: #e92027;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  cursor: pointer;
  height: 40px;
  width: 200px;
  margin: 0 10px;
  border-radius: 20px;
`;

const StyledUserProfileLink = styled.a`
  display: flex;
  background-color: white;
  height: 75px;
  cursor: pointer;
  margin-right: 10px;

  color: var(--color-blue-main);
  max-width: 300px;
  column-gap: 10px;
  align-items: center;
  justify-content: center;
  transition: grid-template-columns 0.5s ease-in;

  p {
    font-weight: bold;
    font-size: 0.9375rem;
  }
  .profile_pic {
    margin: auto;
  }

  .truncate {
    overflow: hidden;
    white-space: nowrap;
    width: 160px;
    text-overflow: ellipsis;
  }

  .logout-button {
    opacity: 0;
    transition-property: opacity, scale;
    transition-delay: 0.3s, 0.1s;

    &:hover {
      scale: 1.3;
    }
  }

  &:hover {
    grid-template-columns: 27px 1fr 20px;

    .logout-button {
      opacity: 100;
    }
  }
`;

const profileImage = css`
  clip-path: circle(50%);
`;
