const FormHotpots = {
  FRANQUICIAS: {
    formId: "9b2acf82-c910-45e4-acde-a215af7498b0",
    targetId: "FRANQUICIAS-FORM",
  },
  UNETE: {
    formId: "bf07a8e7-9992-4fad-958c-291d86b8736a",
    targetId: "JOIN-FORM",
  },
  SUSCRIBE_BLOG_FOOTER: {
    formId: "2e0183da-89fb-4de1-bcfb-244a7167438f",
    targetId: "SUSCRIBE_BLOG_FOOTER",
  },
};


export default FormHotpots;
