import { IModalMessage } from "@interfaces/components/message-modal.interface";
import React, { createContext, useContext, useState, ReactNode } from "react";

interface ModalContextType {
  openModal: (content: any) => void;
  closeModal?: () => void;
}

const ModalContext = createContext<ModalContextType | undefined>(undefined);

export const ModalProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [modalContent, setModalContent] = useState<IModalMessage | null>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const openModal = (content: IModalMessage) => {
    setModalContent(content);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <ModalContext.Provider value={{ openModal, closeModal }}>
      {children}
      {modalContent && (
        <ModalMessage
          {...modalContent}
          open={isOpen}
          handleClose={closeModal}
        />
      )}
    </ModalContext.Provider>
  );
};

export const useModal = () => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error("useModal must be used within a ModalProvider");
  }
  return context;
};

import styled from "@emotion/styled";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import Button from "shared/components/Button";
import { useTranslations } from "next-intl";

export interface ModalMessageProps extends IModalMessage {
  open: boolean;
  handleClose: () => void;
}

const ModalMessage: React.FC<ModalMessageProps> = ({
  variant = "success",
  open,
  handleClose,
  title,
  body,
  buttonText,
}) => {
  const translation = useTranslations("alerts");

  const handleForceClose = () => {
    handleClose();
  };

  return (
    <div>
      <ModalCustom
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <ModalCloseButton onClick={handleForceClose}>
              Cerrar
            </ModalCloseButton>
            {variant === "success" ? (
              <IconSuccess />
            ) : variant === "error" ? (
              <IconError />
            ) : null}
            <Title>{title}</Title>
            <Body>{body}</Body>
            <ContainerButton>
              <Button onClick={handleClose}>{buttonText ?? "Ok"}</Button>
            </ContainerButton>
          </Box>
        </Fade>
      </ModalCustom>
    </div>
  );
};

export default ModalMessage;

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  boxShadow: 24,
  p: 4,
};

const ModalCustom = styled(Modal)(({ theme }) => ({
  "& .MuiBox-root": {
    backgroundColor: "#EFEFF1",
    borderRadius: "20px",
    border: "0px",
  },
}));

const IconSuccess = styled(CheckCircleOutlineIcon)(({ theme }) => ({
  color: "var(--color-green-success)",
  margin: "auto",
  width: 80,
  height: 80,
  textAlign: "center",
  display: "block",
  marginBottom: 20,
}));

const IconError = styled(HighlightOffIcon)(({ theme }) => ({
  color: "var(--color-red-error)",
  margin: "auto",
  width: 80,
  height: 80,
  textAlign: "center",
  display: "block",
  marginBottom: 20,
}));

const Title = styled.h5`
  font-size: 16px;
  color: var(--color-blue-main);
  text-align: center;
  display: block;
  margin: 20px 0px;
`;

const Body = styled.h5`
  font-size: 13px;
  color: var(--color-gray-01);
  text-align: center;
`;

const ContainerButton = styled.h5`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0px;
`;

const ModalCloseButton = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  font-weight: 600;
  color: var(--color-gray-01);
  cursor: pointer;
`;
