import { CPagesPath } from "shared/models/const/pages-path";
import { useLocale, useTranslations } from "next-intl";
import { SvgIconComponent } from "@mui/icons-material";

export interface MenuItem {
  slug: string;
  link?: string;
  style?: "bold" | "thin";
  icon?: SvgIconComponent | string;
  children?: MenuItem[];
}

let menu: MenuItem[] = [];
let locale: string;

export default function useUserSettingsMenuItems() {
  const t = useTranslations("SettingsLayout");
  const currentLocale = useLocale();

  if (locale !== currentLocale) menu = [];
  if (menu.length) return menu;

  menu = [
    {
      slug: t("links.header_personal-info"),
      link: CPagesPath.es.profile,
      icon: "/icons/user-profile.svg",
      style: "bold",
      children: [
        {
          slug: t("links.edit-profile"),
          link: CPagesPath.es.profileEditar,
        },
        {
          slug: t("links.change-password"),
          link: CPagesPath.es.profileCambiarClave,
        },
      ],
    },
    {
      slug: t("links.header_favorites-list"),
      icon: "/icons/favorites-list.svg",
      style: "bold",
      link: CPagesPath.es.profileFavorites,
    },
    {
      slug: t("links.header_search-history"),
      icon: "/icons/search-query-list.svg",
      style: "bold",
      link: CPagesPath.es.ProfileSearchHistory,
    },
    {
      slug: t("links.history"),
      icon: "/icons/search-query-list.svg",
      style: "bold",
      link: CPagesPath.es.ProfileLastedSee,
    },
  ];

  return menu;
}