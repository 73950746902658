import React from "react";
import Footer from "shared/components/footer";
import NavBar from "shared/components/nav-bar";
import usePersistParams from "custom-hooks/usePersistParams.hook";
import WhatsAppFloatButton from "../whatssp-float-button";
interface ILayoutProps {
  children: any;
  showFooter?: boolean;
}

export default function Layout({ children, showFooter }: ILayoutProps) {
  usePersistParams();

  return (
    <>
      {/* <WhatsAppFloatButton /> */}
      <NavBar />
      <main>{children}</main>
      {showFooter && <Footer />}
    </>
  );
}
