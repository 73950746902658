import * as React from "react";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import styled from "@emotion/styled";
import Button from "@components/Button";
import { PaperProps } from "@mui/material";
import { CIdioms, IIdioms } from "shared/models/const/idiomsconst";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { useWidth } from "custom-hooks/use-width.hook";

export default function FlatsSelector() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [idiomState, setIdiomState] = React.useState<string>("ES");
  const open = Boolean(anchorEl);
  const router = useRouter();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleIdiomChange = (idiom: IIdioms) => {
    setTimeout(() => {
      setIdiomState(idiom.acronym);
      localStorage.setItem("idiom", idiom.acronym);
      router.push(router.asPath, router.asPath, {
        locale: idiom.acronym.toLowerCase(),
      });
    }, 1000);
  };
  const LoaderIdiom = () => {
    // get idiom from localstorage
    const idiom = localStorage.getItem("idiom");
    // if idiom is not null set idiom state if not set timeout to click on idiom selector
    if (idiom) {
      setIdiomState(idiom.toLowerCase());
    } else {
      localStorage.setItem('idiom', idiomState.toUpperCase())
    }
  };

  React.useEffect(() => {
    LoaderIdiom();
  }, []);

  return (
    <FlagsContainer>
      <Button
        id="idiom-selector"
        variant="text"
        className="button_selector"
        onClick={handleClick}
      >
        {idiomState}
      </Button>

      <Menu
        className="menu"
        anchorEl={anchorEl}
        id="idiom-menu"
        open={open}
        slotProps={{
          backdrop: {
            invisible: false,
          },
        }}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={paperProps}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <TitleSelector>Seleccione un idioma</TitleSelector>
        <Box sx={{ mb: 2 }} />
        {CIdioms.map((idiom, i, row) => (
          <div key={i}>
            <CustomMenuItem onClick={() => handleIdiomChange(idiom)}>
              <Image src={idiom.icon} alt={idiom.alt} width={30} height={20} />
              <span>
                {idiom.name} ({idiom.acronym})
              </span>
            </CustomMenuItem>
            {i != row.length - 1 && <Divider />}
          </div>
        ))}
      </Menu>
    </FlagsContainer>
  );
}

const FlagsContainer = styled.div`
  border-left: 1px solid #000;

  padding: 0 0px;
  // margin-right:-10px;
  font-weight: 700;
  color: var(--color-blue-main);
  display: flex;
  align-items: center;

  .button_selector.MuiButton-root {
    width: 50px;
    min-width: 50px;
    padding: 0px;
    font-size: 0.8988rem;
    font-weight: 700;
    color: var(--color-blue-main);
    font-family: var(--FF-SF);
  }
  .title_selector {
    font-size: 1rem;
    font-weight: 700;
    color: var(--color-blue-main);
    font-family: var(--FF-SF);
    padding: 10px 0;
  }
  @media (max-width: 768px) {
    border-left: none;
  }
`;

const TitleSelector = styled.span`
  font-size: 0.938rem;
  font-weight: 700;
  color: var(--color-blue-main);
  font-family: var(--FF-SF);
  padding: 15px 0;
`;

const CustomMenuItem = styled(MenuItem)`
  font-size: 14px;
  font-weight: 700;
  color: var(--color-blue-main);
  font-family: var(--FF-SF);
  padding: 10px 0;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  column-gap: 10px;
  img {
    width: 50px;
    height: 30px;
  }
  span {
    font-size: 0.813rem;
  }
`;

const paperProps: PaperProps = {
  elevation: 1,
  sx: {
    p: 2,
    overflow: "visible",
    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
    background: "white",
    mt: 1.5,
    "&:before": {
      content: '""',
      display: "block",
      position: "absolute",
      top: 0,
      right: 14,
      width: 10,
      height: 10,
      bgcolor: "background.paper",
      transform: "translateY(-50%) rotate(45deg)",
      zIndex: 0,
    },
  },
};
