enum NewsletterStatic {
  /**
   * @member {string}
   * @method post
   * @version 2.0
   * @description subscription for newsletter
   */
  Subscription = "/newsletter/v2/subscriptions",
}

export const NewsletterEndpoints = {
  ...NewsletterStatic,
};

export type NewsletterEndpoint = typeof NewsletterEndpoints;
