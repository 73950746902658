import { AnyAction, configureStore, ThunkAction } from "@reduxjs/toolkit";
import authDialogSlice from "./feature/auth/auth-dialog.slice";
import authReducer from "./feature/auth/auth.slice";
import propertyDetailSlice from "./property-detail";
import propertiesLoadingSlice from "./properties-loading";

export const store = configureStore({
  reducer: {
    Auth: authReducer,
    AuthDialog: authDialogSlice,
    propertyDetail: propertyDetailSlice,
    propertiesLoading: propertiesLoadingSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  AnyAction
>;
