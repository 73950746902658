import styled from "@emotion/styled";
import { Divider, Grid } from "@mui/material";
import { Form, Formik } from "formik";
import { useTranslations } from "next-intl";
import React, { useState } from "react";
import { PhoneMask, TextMaskInput } from "../mask-components";
import TextField from "../text-field";
import Button from "../Button";
import RecaptchaField from "../recaptcha-field";
import { css } from "@emotion/react";
import { ISignUpForm } from "shared/models/interfaces/clients.interfaces";
import { userSignUp } from "api/clients/clients.request";
import { useMutation } from "@tanstack/react-query";
import OAuthButtons from "../oAuthButtons";
import { ModalMessage } from "../message-modal";
import { object, ref, string } from "yup";
import { RequestErrorModal } from "@components/modals/error-modal";
import { AxiosError } from "axios";
import PasswordField from "@components/text-field/password-field";
import { useRouter } from "next/router";

interface ISignUpFormProps {
  onSignUpSuccess?: () => void;
  openSignIn?: () => void;
  isPage?: boolean;
}

const initialValues: ISignUpForm = {
  full_name: "",
  email: "",
  phone: "",
  password: "",
  confirmPassword: "",
  "g-recaptcha-response": "",
};

export default function SignUpForm(props: ISignUpFormProps) {
  const { onSignUpSuccess, openSignIn, isPage= false } = props;
  const t = useTranslations("signUpForm");
  const tErrors = useTranslations("error");
  const router = useRouter();
  const [openSuccessModal, onOpenSuccessModal] = useState(false);
  const [responseErrors, setResponseErrors] = useState<AxiosError | null>(null);

  const validationSchema = object({
    full_name: string().required(tErrors("required")),
    phone: string(),
    email: string().email(tErrors("email")).required(tErrors("required")),
    password: string()
      .min(8, tErrors("field-min", { min: 8 }))
      .required(tErrors("required")),
    confirmPassword: string()
      .oneOf([ref("password"), null], tErrors("passwordMustMatch"))
      .required(tErrors("required")),
    // "g-recaptcha-response": string().required(tErrors("captcha")),
  });

  const redirectToSignIn = () => {
    if(isPage){ 
      router.push("/acceder")
      return;  
    };
    if (openSignIn) openSignIn();
  }

  const { mutateAsync, isLoading } = useMutation(userSignUp, {
    onSuccess: () => onOpenSuccessModal(true),
    onError: setResponseErrors,
  });

  function handleErrorModalClose() {
    setResponseErrors(null);
  }

  function handleSuccessModal() {
    onOpenSuccessModal(false);

    if (onSignUpSuccess) onSignUpSuccess();
  }

  const onSubmit = (values: ISignUpForm) => mutateAsync(values);

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {(formikProps) => {
          return (
            <Form className="register-form-hubspot-remaxrd" >
              <Grid container mb={5}>
                <Grid item xs={12} sm={9} textAlign="center" margin="auto">
                  <h3 css={styles.title}>
                    <BoldTitle>{t("header.title")}</BoldTitle>
                    &nbsp;
                    {/* <ThinTitle>RE/MAX RD</ThinTitle> */}
                  </h3>
                  <p css={styles.subtitle}>{t("header.message")}</p>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={9} mx="auto">
                  <OAuthButtons></OAuthButtons>
                </Grid>

                <Grid
                  container
                  sx={{ mt: 4, mb: 1 }}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Grid item xs={5}>
                    <Divider variant="middle" />
                  </Grid>
                  <Grid item xs={2} sx={{ textAlign: "center" }}>
                    <OpcionalMsg>o bien</OpcionalMsg>
                  </Grid>
                  <Grid item xs={5}>
                    <Divider variant="middle" />
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={8} margin="auto">
                  <TextField
                    name="full_name"
                    label={t("field.full_name") + "*"}
                    inputComponent={TextMaskInput}
                  />
                </Grid>
                <Grid item xs={12} sm={8} margin="auto">
                  <TextField name="email" label={t("field.email") + "*"} />
                </Grid>
                <Grid item xs={12} sm={8} margin="auto">
                  <TextField
                    name="phone"
                    label={t("field.phone")}
                    inputComponent={PhoneMask}
                  />
                </Grid>
                <Grid item xs={12} sm={8} margin="auto">
                  <PasswordField
                    name="password"
                    label={t("field.password") + "*"}
                  />
                </Grid>
                <Grid item xs={12} sm={8} margin="auto">
                  <PasswordField
                    name="confirmPassword"
                    label={t("field.confirmPassword") + "*"}
                  />
                </Grid>
                {/* <RecaptchaField
                  formikProps={formikProps}
                  fieldName="g-recaptcha-response"
                /> */}
                <Grid item xs={12} mb={5} textAlign="center">
                  <Button
                    type="submit"
                    fs="12px"
                    bg="--color-secondary"
                    thin_button
                    disabled={isLoading}
                    loadingMessage={isLoading ? t("sending") : null}
                  >
                    {t("submitButton")}
                  </Button>
                </Grid>
                <Grid item xs={12} textAlign="center">
                  <SignInButton>¿Ya tienes una cuenta?</SignInButton>
                  &nbsp;|&nbsp;
                  <SignInButton className="redirect" onClick={redirectToSignIn}>
                    Iniciar sesión
                  </SignInButton>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
      <ModalMessage
        title={t("successfulSignUp.title")}
        body={t("successfulSignUp.content-text")}
        buttonText="Ok"
        open={openSuccessModal}
        handleClose={handleSuccessModal}
        variant="success"
      />
      <RequestErrorModal
        open={!!responseErrors}
        error={responseErrors}
        close={handleErrorModalClose}
      />
    </>
  );
}

const BoldTitle = styled.span`
  font-weight: 700;
  text-transform: uppercase;
`;

const ThinTitle = styled.span`
  font-weight: 300;
  text-transform: uppercase;
`;

const OpcionalMsg = styled.span`
  font-size: 12px;
  color: var(--color-dark-gray);
  text-transform: uppercase;
  font-weight: 500;
`;

const SignInButton = styled.a`
  font-size: 14px;
  font-weight: 500;
  line-height: 13px;
  color: var(--color-gray-02);
  cursor: pointer;
  &.redirect {
    color: var(--color-blue-main);
    font-weight: 700;
  }
  // margin-top: -20px;
`;
const styles = {
  title: {
    marginBottom: 21,
  },
  subtitle: css({
    fontSize: 11,
    fontWeight: 500,
    lineHeight: "13px",
    color: "var(--color-dark-gray)",
  }),
  oauth_btn_lbl: {
    fontWeight: "bold",
    marginLeft: "10px",
  },
};
