import { useAppDispatch, useAppSelector } from "custom-hooks/redux.hooks";
import React, { useEffect } from "react";
import {
  closeAuthDialog,
  openAuthDialog,
} from "store/feature/auth/auth-dialog.slice";
import CompleteSignUpDialog from "../dialogs/complete-signup.dialog";
import RestorePasswordDialog from "../restore-password-dialog";
import SignInDialog from "../signin-dialog";
import SignUpDialog from "../signup-dialog";

export default function AuthDialogs() {
  const [openDialog, user] = useAppSelector(({ AuthDialog, Auth }) => [
    AuthDialog.dialog,
    Auth.user,
  ]);
  const dispatch = useAppDispatch();

  const handleClose = () => dispatch(closeAuthDialog());

  useEffect(() => {
    if (user && !user.type) {
      dispatch(openAuthDialog("complete-signup"));
    }
  }, [user, dispatch]);

  return (
    <>
      <SignInDialog
        open={openDialog === "signin"}
        onClose={handleClose}
        openSignUp={() => dispatch(openAuthDialog("signup"))}
        openRestorePassword={() => dispatch(openAuthDialog("restore-password"))}
      />
      <SignUpDialog
        open={openDialog === "signup"}
        onClose={handleClose}
        openSignIn={() => dispatch(openAuthDialog("signin"))}
      />
      <RestorePasswordDialog
        open={openDialog === "restore-password"}
        onClose={handleClose}
      />
      <CompleteSignUpDialog open={openDialog === "complete-signup"} />
    </>
  );
}
