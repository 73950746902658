import styled from "@emotion/styled";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import Button from "shared/components/Button";
import { useTranslations } from "next-intl";
import { IModalMessage } from "shared/models/interfaces/components/message-modal.interface";
import { useEffect, useState } from "react";

export function ModalMessage({ variant = "success", ...props }: IModalMessage) {
  const translation = useTranslations("alerts");
  const ForceClose = () => {
    if (props.handleClose) props.handleClose("forceClose");
  };
  return (
    <div>
      <ModalCustom
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={props.open}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        className={props.className}
      >
        <Fade in={props.open}>
          <Box sx={style}>
            <ModalCloseButton onClick={ForceClose}>Cerrar</ModalCloseButton>
            {variant === "success" ? (
              <IconSuccess />
            ) : variant === "error" ? (
              <IconError />
            ) : null}
            <Title>{props.title}</Title>
            <Body>{props.body}</Body>
            <ContainerButton>
              {
                <Button onClick={() => props.handleClose()}>
                  {props.buttonText ?? "Ok"}
                </Button>
              }
            </ContainerButton>
          </Box>
        </Fade>
      </ModalCustom>
    </div>
  );
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  boxShadow: 24,
  p: 4,
};

const ModalCustom = styled(Modal)(({ theme }) => ({
  "& .MuiBox-root": {
    backgroundColor: "#EFEFF1",
    borderRadius: "20px",
    border: "0px",
  },
}));

const IconSuccess = styled(CheckCircleOutlineIcon)(({ theme }) => ({
  color: "var(--color-green-success)",
  margin: "auto",
  width: 80,
  height: 80,
  textAlign: "center",
  display: "block",
  marginBottom: 20,
}));

const IconError = styled(HighlightOffIcon)(({ theme }) => ({
  color: "var(--color-red-error)",
  margin: "auto",
  width: 80,
  height: 80,
  textAlign: "center",
  display: "block",
  marginBottom: 20,
}));

const Title = styled.h5`
  font-size: 16px;
  color: var(--color-blue-main);
  text-align: center;
  display: block;
  margin: 20px 0px;
`;

const Body = styled.h5`
  font-size: 13px;
  color: var(--color-gray-01);
  text-align: center;
`;

const ContainerButton = styled.h5`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0px;
`;

const ModalCloseButton = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  font-weight: 600;
  color: var(--color-gray-01);
  cursor: pointer;
`;
