import styled from "@emotion/styled";
import {
  Divider,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SvgIcon,
} from "@mui/material";
import { MenuItem } from "custom-hooks/use-user-settings-menu-items.hook";
import Link from "next/link";
import React from "react";
import { INavLink } from "shared/models/interfaces/components/navlinks.interface";

interface IMobileMenuItemProps {
  item: INavLink | MenuItem;
  handleOpen?: () => void;
}
export default function MobileMenuItem({
  item,
  handleOpen,
}: IMobileMenuItemProps) {
  return (
    <>
      <Divider />
      <ListItem>
        {handleOpen ? (
          <ListItemButton
            sx={{ textAlign: "left", whiteSpace: "nowrap" }}
            onClick={handleOpen}
          >
            <ItemImageIcon item={item} />
            <ListItemTextCustom>{item.slug}</ListItemTextCustom>
          </ListItemButton>
        ) : (
          <Link href={item.link ?? ""}>
            <ListItemButton
              LinkComponent={"a"}
              sx={{ textAlign: "left", whiteSpace: "nowrap" }}
            >
              <ItemImageIcon item={item} />
              <ListItemTextCustom>{item.slug}</ListItemTextCustom>
            </ListItemButton>
          </Link>
        )}
      </ListItem>
      <Divider />
      {(item as MenuItem).children?.length &&
        (item as MenuItem).children?.map((item, i) => (
          <MobileMenuItem key={i} item={item} />
        ))}
    </>
  );
}

interface IItemImageIconProps {
  item: INavLink;
}

const ItemImageIcon = ({ item }: IItemImageIconProps) => (
  <ListItemIcon sx={{ minWidth: 36 }}>
    {item.iconComponent ? (
      item.iconComponent
    ) : typeof item.icon === "string" ? (
      <Image src={item.icon} alt="profile icon" />
    ) : (
      item.icon && <SvgIcon component={item.icon} inheritViewBox />
    )}
  </ListItemIcon>
);

const Image = styled.img`
  width: 30px;
  height: 20px;
  filter: grayscale(1);
`;

const ListItemTextCustom = styled(ListItemText)`
  .MuiTypography-root {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 155px;
  }
`;
