export function createFormData(
  jsonObject: { [key: string]: any },
  parentKey?: string,
  carryFormData?: FormData
): FormData {
  const formData = carryFormData || new FormData();
  let index = 0;

  for (const key in jsonObject) {
    if (Object.prototype.hasOwnProperty.call(jsonObject, key)) {
      if (jsonObject[key] !== null && jsonObject[key] !== undefined) {
        let propName = parentKey || key;
        if (parentKey && _isObject(jsonObject)) {
          propName = parentKey + `[${key}]`;
        }
        if (parentKey && Array.isArray(jsonObject)) {
          propName = parentKey + `[${index}]`;
        }
        if (jsonObject[key] instanceof File) {
          formData.append(propName, jsonObject[key]);
        } else if (jsonObject[key] instanceof FileList) {
          for (let j = 0; j < jsonObject[key].length; j++) {
            formData.append(
              propName + `[${j}]`,
              jsonObject[key].item(j)
            );
          }
        } else if (
          Array.isArray(jsonObject[key]) ||
          _isObject(jsonObject[key])
        ) {
          createFormData(jsonObject[key], propName, formData);
        } else if (typeof jsonObject[key] === "boolean") {
          formData.append(propName, +jsonObject[key] ? "1" : "0");
        } else {
          formData.append(propName, jsonObject[key]);
        }
      }
      index++;
    }
  }
  return formData;
}

function _isObject(val: any) {
  return !Array.isArray(val) && typeof val === "object" && !!val;
}