import React from "react";
import styled from "@emotion/styled";
function SocialLinks() {
  return (
    <ContentIcons>
      {CSocialLinksFooter.map((item, i) => (
        <a key={i} href={item.path} target="_blank" rel="noreferrer">
          <img src={item.picture} alt={item.alt} />
        </a>
      ))}
    </ContentIcons>
  );
}

export default SocialLinks;

const ContentIcons = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  margin-top: 20px;
  margin-left: 10px;
  a {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #fff;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    padding: 4px;
    text-align: center;
    width: 40px;
    height: 40px;
    margin-right: 13px;
  }
  img {
    width: 20px;
    height: 20px;
    vertical-align: middle;
  }
  @media screen and (max-width: 787px) {
    width: min-content;
    margin: 10px auto;
  }
`;

const CSocialLinksFooter = [
  {
    picture: "/icons/facebook.png",
    alt: "Facebook",
    path: "https://www.facebook.com/remaxrd",
    target: "_blank",
  },
  {
    picture: "/icons/TwitterX.svg",
    alt: "Twitter",
    path: "https://twitter.com/Remax_RD",
    target: "_blank",
  },
  {
    picture: "/icons/instagram-symbol.png",
    alt: "Instagram",
    path: "https://instagram.com/remax_rd?igshid=YmMyMTA2M2Y=",
    target: "_blank",
  },
];
