import { ModalMessage } from "@components/message-modal";
import { css } from "@emotion/css";
import styled from "@emotion/styled";
import { AxiosError } from "axios";
import { useTranslations } from "next-intl";
import React from "react";

interface IErrorDialogProps {
  open: boolean;
  title?: string;
  error?: AxiosError | null;
  message?: string | null;
  buttonMsg?: string;
  close: () => void;
}

/**
 * General purpose error modal for requests.
 * Pass either an `error` to parse from axios (AxiosError)
 * or a `message` string to display.
 */
export function RequestErrorModal({
  open,
  title,
  error,
  message,
  buttonMsg,
  close,
}: IErrorDialogProps) {
  const errorBody = message ? (
    <ErrorSubtitle>{message}</ErrorSubtitle>
  ) : error ? (
    <ParseReqError {...error} />
  ) : null;

  return (
    <ModalMessage
      open={open}
      variant="error"
      title={title ?? "Error"}
      body={errorBody}
      handleClose={close}
      buttonText={buttonMsg ?? "OK"}
    />
  );
}

function ParseReqError({ response, request }: AxiosError<any>) {
  const t = useTranslations("error.http");
  let errorsBody = null;
  let errorMsg = t("default");

  // The request was made and the server responded with a status code
  // that falls out of the range of 2xx
  // console.log(response, 'a')

  if (response) {
    const {
      status,
      data: { message, errors },
    } = response;
    console.log(response, "a");
    if (message) {
      errorMsg = message;
    } else if (status > 499) {
      errorMsg = t("500");
    } else if (status === 401) {
      errorMsg = t("401");
    } else if (status === 403) {
      errorMsg = t("403");
    } else if (status === 404) {
      errorMsg = t("404");
    } else if ([409, 406].includes(status)) {
      errorMsg = t("406");
    }

    if (status === 422) {
      if (typeof errors === "object") {
        const parsedErrors = Object.values(errors)
          .map((errors) => errors)
          .flat() as string[];
        console.log(parsedErrors)
        errorsBody =
          parsedErrors.length > 1 ? (
            <ul className={errorList} role="list">
              {parsedErrors.map((error, i) => (
                <li key={i}>{error}</li>
              ))}
            </ul>
          ) : (
            <p className={singleError}>{parsedErrors.at(0)}</p>
          );
      }
    }
  } else if (request) {
    // The request was made but no response was received
    // `request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    console.log(request);
  }

  return (
    <>
      <ErrorSubtitle>{errorMsg}</ErrorSubtitle>
      {errorsBody}
    </>
  );
}

const ErrorSubtitle = styled.strong`
  font-size: 14px;
  margin-bottom: 10px;
  color: var(--color-dark-gray);
`;

const errorList = css`
  list-style: inside;
  text-align: left;
  margin: 0 20px;
  margin-top: 10px;
`;

const singleError = css`
  margin-top: 10px;
`;
