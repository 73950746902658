import React from "react";

function dataLayerEvent() {
  const event = (info: IEvent) => {
    const Widows: any = window;
    if (typeof Widows.dataLayer != undefined && typeof Widows != undefined) {
      Widows.dataLayer.push({
        event: info.event_name,
        ...info,
      });
    }
  };

  return { event };
}

export default dataLayerEvent;
interface IEvent {
  event_name: string;
  body: any;
}
