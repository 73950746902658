import styled from "@emotion/styled";
import { Grid } from "@mui/material";
import { Form, Formik } from "formik";
import { useTranslations } from "next-intl";
import React, { useState } from "react";
import TextField from "../text-field";
import Button from "../Button";
import * as yup from "yup";
import { useMutation } from "@tanstack/react-query";
import { IForgotPassword } from "shared/models/interfaces/clients.interfaces";
import { userForgotPassword } from "api/clients/clients.request";
import { ModalMessage } from "../message-modal";
import RecaptchaField from "../recaptcha-field";

interface IRestorePasswordProps {
  onClose?: () => void;
}

const initialValues1: IForgotPassword = {
  email: "",
  "g-recaptcha-response": "",
};

export default function ForgotPasswordForm(props: IRestorePasswordProps) {
  const { onClose } = props;
  const [showMessage, setShowMessage] = useState(false);
  const t = useTranslations("restorePasswordForm");
  const tErrors = useTranslations("error");

  const validationSchema = yup.object({
    email: yup.string().email(tErrors("email")).required(tErrors("required")),
    // "g-recaptcha-response": yup.string().required(tErrors("captcha")),
  });

  const { mutateAsync, isLoading } = useMutation(userForgotPassword, {
    onSuccess: () => setShowMessage(true),
    onError: console.log, //?
  });

  function onSuccessClose() {
    setShowMessage(false);

    if (onClose) onClose();
  }

  const onSubmit = (values: any) => mutateAsync(values);

  return (
    <>
      <Grid container mb={5}>
        <Grid item xs={12} sm={9} textAlign="center" margin="auto">
          <h3 css={styles.title}>
            <BoldTitle>{t("header.title-left")}</BoldTitle>
            &nbsp;
            <ThinTitle>{t("header.title-right")}</ThinTitle>
          </h3>
        </Grid>
      </Grid>
      <Formik
        initialValues={initialValues1}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {(formikProps) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={8} margin="auto">
                <TextField name="email" label={t("field.email")} />
              </Grid>
              {/* <RecaptchaField
                formikProps={formikProps}
                fieldName="g-recaptcha-response"
              /> */}
              <Grid item xs={12} textAlign="center" mb={2}>
                <Button
                  type="submit"
                  fs="12px"
                  bg="--color-secondary"
                  thin_button
                  disabled={isLoading}
                  loadingMessage={isLoading ? t("sending") : null}
                >
                  {t("submitBtn")}
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
      <ModalMessage
        title={t("successMsg.title")}
        body={t("successMsg.content")}
        open={showMessage}
        handleClose={onSuccessClose}
      />
    </>
  );
}

const BoldTitle = styled.span`
  font-weight: 700;
  text-transform: uppercase;
`;

const ThinTitle = styled.span`
  font-weight: 300;
  text-transform: uppercase;
`;

const styles = {
  title: {
    marginBottom: 21,
  },
};
