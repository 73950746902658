import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
interface PropertyDetail extends Property { }
interface I_InitialState extends PropertyDetail { }
const initialState: I_InitialState | {} = {};
const PropertyDetailSlice = createSlice({
  name: "propertyDetail",
  initialState,
  reducers: {
    setPropertyDetail: (
      state: typeof initialState,
      action: PayloadAction<PropertyDetail>
    ) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    clearPropertyDetail: () => { },
  },
});

export const { setPropertyDetail, clearPropertyDetail } =
  PropertyDetailSlice.actions;
export default PropertyDetailSlice.reducer;

// hook
export const usePropertyDetail = () => {
  const property = useSelector(
    (state: any) => state?.propertyDetail
  ) as I_InitialState;

  const dispatch = useDispatch();

  const setDetailPropertyAction = (detail: PropertyDetail) => {
    dispatch(setPropertyDetail(detail));
  };


  const clearPropertyAction = () => {
    dispatch(clearPropertyDetail());
  };

  return {
    property,
    setDetailPropertyAction,
    clearPropertyAction,
  };
};

interface Currency {
  iso: string;
  symbol: string;
}

interface Agent {
  id: number;
  rexapi_id: string;
  international_id: string;
  name: string;
  description: string | null;
  identification: string;
  companyname: string | null;
  company_identification: string | null;
  phone: string;
  phone2: string | null;
  mobile: string;
  fax: string | null;
  email: string;
  address: string;
  reference: string | null;
  birth_date: string;
  url: string | null;
  titulo: string | null;
  isPublished: number;
  is_collection_agent: number;
  is_broker_manager: number;
  template_id: string | null;
  iskeyactive: number;
  apikey: string | null;
  last_invitation_send: string | null;
  created: string;
  modified: string;
  createdby: number;
  modifiedby: number;
  account_id: number;
  group_id: number;
  parent_id: number | null;
  isFirstTime: number;
  isdeleted: number;
  template_operation_id: string | null;
  has_edit_permissions: number;
  agent_plan_id: number;
  is_assistant: number;
  api_key_expire_at: string | null;
  website_realstate: string | null;
  has_external_url: number;
  is_template: number;
  small_picture: string | null;
  medium_picture: string | null;
  large_picture: string | null;
  membershipstatus_id: number;
  gender_id: number;
  agency_id: number;
  is_pending: number;
  is_bpd: number;
  remaxrd_scripts: string | null;
  synchronized_at: string;
  pivot: {
    realstate_id: number;
    people_id: number;
    is_no_broker: number;
  };
}

interface FullImprovement {
  realstate_id: number;
  name: string;
  value: string;
}

interface Picture {
  id: number;
  realstate_id: number;
  pictures: string;
}

interface Agency {
  id: number;
  picture: string;
  name: string;
  email: string;
  phone: string;
  agency_id: number;
  broker: any[];
}

interface Property {
  id: number;
  parent_id: number;
  slug: number;
  name: string;
  property_title: string | null;
  is_favorite: boolean | null;
  address: string;
  description: string | null;
  price: string;
  alternate_price: string;
  currency: Currency;
  business_type: string;
  arrangement: string;
  realstate_type: string;
  sqm_construction: string;
  sqm_land: string;
  parking_spots: number;
  bedrooms: number;
  bathrooms: number;
  half_bathrooms: number;
  dressers: null;
  city: string;
  city_id: number;
  sector: string;
  sector_id: number;
  latitude: number;
  longitude: number;
  maintenance_fee: string;
  is_collection: boolean | null;
  status: string;
  visit_status: number;
  floor_level: number;
  floors_total: number | null;
  pumps: any | null; // El tipo de datos de "pumps" no está especificado en los datos proporcionados
  condition_status: string;
  realstate_tour_url: string | null;
  agent_list: Agent[];
  full_improvements: FullImprovement[];
  pictures: Picture[];
  project: null;
  agency: Agency;
  agents: Agent[];
}
