import React from "react";
import { useRouter } from "next/router";

interface IUseGetPersistParamsParams {
  useConque?: "?" | "&" | "";
  onlyCode?: boolean;
  onlyId?: boolean;
}
function useGetPersistParams(config?: IUseGetPersistParamsParams) {
  const router: any = useRouter();
  // if (config?.onlyId) {
  //   if (router?.query?.referer) {
  //     return router?.query?.referer as string;
  //   } else if (router?.query?.areferer) {
  //     return router?.query?.areferer as string;
  //   }
  // } else
  if (!config?.onlyCode) {
    if (config?.onlyId) {
      if (router?.query?.referer) {
        return router?.query?.referer as string;
      } else if (router?.query?.areferer) {
        return router?.query?.areferer as string;
      }
    } else if (router?.query?.referer) {
      return `${config?.useConque ?? "?"}referer=${router?.query?.referer}`;
    } else if (router?.query?.areferer) {
      return `${config?.useConque ?? "?"}areferer=${router?.query?.areferer}`;
    }
  } else {
    if (router?.query?.referer) {
      return { referer: router?.query?.referer as string };
    } else if (router?.query?.areferer) {
      return { areferer: router?.query?.areferer as string };
    }
  }
  return "";
}

export default useGetPersistParams;
