import { ClientUser } from "shared/models/interfaces/client-user.interface";

export function userState(): ClientUser | null {
  let user = getLocalStorageItem("user");
  let parsedUser = null;

  if (user) {
    try {
      parsedUser = JSON.parse(user);
    } catch (e) {
      console.error("Error parsing user");
    }
  }

  return parsedUser;
}

export function getUserToken() {
  let token = getLocalStorageItem("token");

  return token;
}

function getLocalStorageItem(key: string) {
  let item = null;

  if (typeof window !== "undefined") {
    item = localStorage.getItem(key);
  }

  return item;
}
