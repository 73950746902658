import { css } from "@emotion/css";
import { useEffect, useState } from "react";

interface IProfilePictureProps {
  src: string | null;
  size?: number;
}

export default function ProfilePicture({
  src,
  size = 50,
}: IProfilePictureProps) {
  const [imgSrc, setImgSrc] = useState(src);
  const defaultImgSrc = "/icons/user_profile_icon1.svg";
  const profilePicture = css`
    display: grid;
    grid-template-columns: minmax(0, ${size}px);
    font-size: ${size < 50 ? size / 3 : 12}px;

    img {
      background-color: var(--color-gray-02);
      padding: ${src ? 0 : "1px"};
      width: 100%;
      object-fit: cover;
      aspect-ratio: 1/1; //maintain aspect ration
      border-radius: 50%;
    }
  `;

  useEffect(() => {
    setImgSrc(src);
  }, [src]);

  return (
    <div className={profilePicture}>
      <img
        onError={() => setImgSrc(defaultImgSrc)}
        src={imgSrc ?? defaultImgSrc}
        alt="profile picture"
      />
    </div>
  );
}
